export const COUNTRIES = [
    {
        label: 'Afghanistan',
        dialCode: '+93',
        value: 'AF'
    },
    {
        label: 'Aland Islands',
        dialCode: '+358',
        value: 'AX'
    },
    {
        label: 'Albania',
        dialCode: '+355',
        value: 'AL'
    },
    {
        label: 'Algeria',
        dialCode: '+213',
        value: 'DZ'
    },
    {
        label: 'AmericanSamoa',
        dialCode: '+1684',
        value: 'AS'
    },
    {
        label: 'Andorra',
        dialCode: '+376',
        value: 'AD'
    },
    {
        label: 'Angola',
        dialCode: '+244',
        value: 'AO'
    },
    {
        label: 'Anguilla',
        dialCode: '+1264',
        value: 'AI'
    },
    {
        label: 'Antarctica',
        dialCode: '+672',
        value: 'AQ'
    },
    {
        label: 'Antigua and Barbuda',
        dialCode: '+1268',
        value: 'AG'
    },
    {
        label: 'Argentina',
        dialCode: '+54',
        value: 'AR'
    },
    {
        label: 'Armenia',
        dialCode: '+374',
        value: 'AM'
    },
    {
        label: 'Aruba',
        dialCode: '+297',
        value: 'AW'
    },
    {
        label: 'Australia',
        dialCode: '+61',
        value: 'AU'
    },
    {
        label: 'Austria',
        dialCode: '+43',
        value: 'AT'
    },
    {
        label: 'Azerbaijan',
        dialCode: '+994',
        value: 'AZ'
    },
    {
        label: 'Bahamas',
        dialCode: '+1242',
        value: 'BS'
    },
    {
        label: 'Bahrain',
        dialCode: '+973',
        value: 'BH'
    },
    {
        label: 'Bangladesh',
        dialCode: '+880',
        value: 'BD'
    },
    {
        label: 'Barbados',
        dialCode: '+1246',
        value: 'BB'
    },
    {
        label: 'Belarus',
        dialCode: '+375',
        value: 'BY'
    },
    {
        label: 'Belgium',
        dialCode: '+32',
        value: 'BE'
    },
    {
        label: 'Belize',
        dialCode: '+501',
        value: 'BZ'
    },
    {
        label: 'Benin',
        dialCode: '+229',
        value: 'BJ'
    },
    {
        label: 'Bermuda',
        dialCode: '+1441',
        value: 'BM'
    },
    {
        label: 'Bhutan',
        dialCode: '+975',
        value: 'BT'
    },
    {
        label: 'Bolivia, Plurinational State of',
        dialCode: '+591',
        value: 'BO'
    },
    {
        label: 'Bosnia and Herzegovina',
        dialCode: '+387',
        value: 'BA'
    },
    {
        label: 'Botswana',
        dialCode: '+267',
        value: 'BW'
    },
    {
        label: 'Brazil',
        dialCode: '+55',
        value: 'BR'
    },
    {
        label: 'British Indian Ocean Territory',
        dialCode: '+246',
        value: 'IO'
    },
    {
        label: 'Brunei Darussalam',
        dialCode: '+673',
        value: 'BN'
    },
    {
        label: 'Bulgaria',
        dialCode: '+359',
        value: 'BG'
    },
    {
        label: 'Burkina Faso',
        dialCode: '+226',
        value: 'BF'
    },
    {
        label: 'Burundi',
        dialCode: '+257',
        value: 'BI'
    },
    {
        label: 'Cambodia',
        dialCode: '+855',
        value: 'KH'
    },
    {
        label: 'Cameroon',
        dialCode: '+237',
        value: 'CM'
    },
    {
        label: 'Canada',
        dialCode: '+1',
        value: 'CA'
    },
    {
        label: 'Cape Verde',
        dialCode: '+238',
        value: 'CV'
    },
    {
        label: 'Cayman Islands',
        dialCode: '+ 345',
        value: 'KY'
    },
    {
        label: 'Central African Republic',
        dialCode: '+236',
        value: 'CF'
    },
    {
        label: 'Chad',
        dialCode: '+235',
        value: 'TD'
    },
    {
        label: 'Chile',
        dialCode: '+56',
        value: 'CL'
    },
    {
        label: 'China',
        dialCode: '+86',
        value: 'CN'
    },
    {
        label: 'Christmas Island',
        dialCode: '+61',
        value: 'CX'
    },
    {
        label: 'Cocos (Keeling) Islands',
        dialCode: '+61',
        value: 'CC'
    },
    {
        label: 'Colombia',
        dialCode: '+57',
        value: 'CO'
    },
    {
        label: 'Comoros',
        dialCode: '+269',
        value: 'KM'
    },
    {
        label: 'Congo',
        dialCode: '+242',
        value: 'CG'
    },
    {
        label: 'Congo, The Democratic Republic of the Congo',
        dialCode: '+243',
        value: 'CD'
    },
    {
        label: 'Cook Islands',
        dialCode: '+682',
        value: 'CK'
    },
    {
        label: 'Costa Rica',
        dialCode: '+506',
        value: 'CR'
    },
    {
        label: "Cote d'Ivoire",
        dialCode: '+225',
        value: 'CI'
    },
    {
        label: 'Croatia',
        dialCode: '+385',
        value: 'HR'
    },
    {
        label: 'Cuba',
        dialCode: '+53',
        value: 'CU'
    },
    {
        label: 'Cyprus',
        dialCode: '+357',
        value: 'CY'
    },
    {
        label: 'Czech Republic',
        dialCode: '+420',
        value: 'CZ'
    },
    {
        label: 'Denmark',
        dialCode: '+45',
        value: 'DK'
    },
    {
        label: 'Djibouti',
        dialCode: '+253',
        value: 'DJ'
    },
    {
        label: 'Dominica',
        dialCode: '+1767',
        value: 'DM'
    },
    {
        label: 'Dominican Republic',
        dialCode: '+1849',
        value: 'DO'
    },
    {
        label: 'Ecuador',
        dialCode: '+593',
        value: 'EC'
    },
    {
        label: 'Egypt',
        dialCode: '+20',
        value: 'EG'
    },
    {
        label: 'El Salvador',
        dialCode: '+503',
        value: 'SV'
    },
    {
        label: 'Equatorial Guinea',
        dialCode: '+240',
        value: 'GQ'
    },
    {
        label: 'Eritrea',
        dialCode: '+291',
        value: 'ER'
    },
    {
        label: 'Estonia',
        dialCode: '+372',
        value: 'EE'
    },
    {
        label: 'Ethiopia',
        dialCode: '+251',
        value: 'ET'
    },
    {
        label: 'Falkland Islands (Malvinas)',
        dialCode: '+500',
        value: 'FK'
    },
    {
        label: 'Faroe Islands',
        dialCode: '+298',
        value: 'FO'
    },
    {
        label: 'Fiji',
        dialCode: '+679',
        value: 'FJ'
    },
    {
        label: 'Finland',
        dialCode: '+358',
        value: 'FI'
    },
    {
        label: 'France',
        dialCode: '+33',
        value: 'FR'
    },
    {
        label: 'French Guiana',
        dialCode: '+594',
        value: 'GF'
    },
    {
        label: 'French Polynesia',
        dialCode: '+689',
        value: 'PF'
    },
    {
        label: 'Gabon',
        dialCode: '+241',
        value: 'GA'
    },
    {
        label: 'Gambia',
        dialCode: '+220',
        value: 'GM'
    },
    {
        label: 'Georgia',
        dialCode: '+995',
        value: 'GE'
    },
    {
        label: 'Germany',
        dialCode: '+49',
        value: 'DE'
    },
    {
        label: 'Ghana',
        dialCode: '+233',
        value: 'GH'
    },
    {
        label: 'Gibraltar',
        dialCode: '+350',
        value: 'GI'
    },
    {
        label: 'Greece',
        dialCode: '+30',
        value: 'GR'
    },
    {
        label: 'Greenland',
        dialCode: '+299',
        value: 'GL'
    },
    {
        label: 'Grenada',
        dialCode: '+1473',
        value: 'GD'
    },
    {
        label: 'Guadeloupe',
        dialCode: '+590',
        value: 'GP'
    },
    {
        label: 'Guam',
        dialCode: '+1671',
        value: 'GU'
    },
    {
        label: 'Guatemala',
        dialCode: '+502',
        value: 'GT'
    },
    {
        label: 'Guernsey',
        dialCode: '+44',
        value: 'GG'
    },
    {
        label: 'Guinea',
        dialCode: '+224',
        value: 'GN'
    },
    {
        label: 'Guinea-Bissau',
        dialCode: '+245',
        value: 'GW'
    },
    {
        label: 'Guyana',
        dialCode: '+595',
        value: 'GY'
    },
    {
        label: 'Haiti',
        dialCode: '+509',
        value: 'HT'
    },
    {
        label: 'Holy See (Vatican City State)',
        dialCode: '+379',
        value: 'VA'
    },
    {
        label: 'Honduras',
        dialCode: '+504',
        value: 'HN'
    },
    {
        label: 'Hong Kong',
        dialCode: '+852',
        value: 'HK'
    },
    {
        label: 'Hungary',
        dialCode: '+36',
        value: 'HU'
    },
    {
        label: 'Iceland',
        dialCode: '+354',
        value: 'IS'
    },
    {
        label: 'India',
        dialCode: '+91',
        value: 'IN'
    },
    {
        label: 'Indonesia',
        dialCode: '+62',
        value: 'ID'
    },
    {
        label: 'Iran, Islamic Republic of Persian Gulf',
        dialCode: '+98',
        value: 'IR'
    },
    {
        label: 'Iraq',
        dialCode: '+964',
        value: 'IQ'
    },
    {
        label: 'Ireland',
        dialCode: '+353',
        value: 'IE'
    },
    {
        label: 'Isle of Man',
        dialCode: '+44',
        value: 'IM'
    },
    {
        label: 'Israel',
        dialCode: '+972',
        value: 'IL'
    },
    {
        label: 'Italy',
        dialCode: '+39',
        value: 'IT'
    },
    {
        label: 'Jamaica',
        dialCode: '+1876',
        value: 'JM'
    },
    {
        label: 'Japan',
        dialCode: '+81',
        value: 'JP'
    },
    {
        label: 'Jersey',
        dialCode: '+44',
        value: 'JE'
    },
    {
        label: 'Jordan',
        dialCode: '+962',
        value: 'JO'
    },
    {
        label: 'Kazakhstan',
        dialCode: '+77',
        value: 'KZ'
    },
    {
        label: 'Kenya',
        dialCode: '+254',
        value: 'KE'
    },
    {
        label: 'Kiribati',
        dialCode: '+686',
        value: 'KI'
    },
    {
        label: "Korea, Democratic People's Republic of Korea",
        dialCode: '+850',
        value: 'KP'
    },
    {
        label: 'Korea, Republic of South Korea',
        dialCode: '+82',
        value: 'KR'
    },
    {
        label: 'Kuwait',
        dialCode: '+965',
        value: 'KW'
    },
    {
        label: 'Kyrgyzstan',
        dialCode: '+996',
        value: 'KG'
    },
    {
        label: 'Laos',
        dialCode: '+856',
        value: 'LA'
    },
    {
        label: 'Latvia',
        dialCode: '+371',
        value: 'LV'
    },
    {
        label: 'Lebanon',
        dialCode: '+961',
        value: 'LB'
    },
    {
        label: 'Lesotho',
        dialCode: '+266',
        value: 'LS'
    },
    {
        label: 'Liberia',
        dialCode: '+231',
        value: 'LR'
    },
    {
        label: 'Libyan Arab Jamahiriya',
        dialCode: '+218',
        value: 'LY'
    },
    {
        label: 'Liechtenstein',
        dialCode: '+423',
        value: 'LI'
    },
    {
        label: 'Lithuania',
        dialCode: '+370',
        value: 'LT'
    },
    {
        label: 'Luxembourg',
        dialCode: '+352',
        value: 'LU'
    },
    {
        label: 'Macao',
        dialCode: '+853',
        value: 'MO'
    },
    {
        label: 'Macedonia',
        dialCode: '+389',
        value: 'MK'
    },
    {
        label: 'Madagascar',
        dialCode: '+261',
        value: 'MG'
    },
    {
        label: 'Malawi',
        dialCode: '+265',
        value: 'MW'
    },
    {
        label: 'Malaysia',
        dialCode: '+60',
        value: 'MY'
    },
    {
        label: 'Maldives',
        dialCode: '+960',
        value: 'MV'
    },
    {
        label: 'Mali',
        dialCode: '+223',
        value: 'ML'
    },
    {
        label: 'Malta',
        dialCode: '+356',
        value: 'MT'
    },
    {
        label: 'Marshall Islands',
        dialCode: '+692',
        value: 'MH'
    },
    {
        label: 'Martinique',
        dialCode: '+596',
        value: 'MQ'
    },
    {
        label: 'Mauritania',
        dialCode: '+222',
        value: 'MR'
    },
    {
        label: 'Mauritius',
        dialCode: '+230',
        value: 'MU'
    },
    {
        label: 'Mayotte',
        dialCode: '+262',
        value: 'YT'
    },
    {
        label: 'Mexico',
        dialCode: '+52',
        value: 'MX'
    },
    {
        label: 'Micronesia, Federated States of Micronesia',
        dialCode: '+691',
        value: 'FM'
    },
    {
        label: 'Moldova',
        dialCode: '+373',
        value: 'MD'
    },
    {
        label: 'Monaco',
        dialCode: '+377',
        value: 'MC'
    },
    {
        label: 'Mongolia',
        dialCode: '+976',
        value: 'MN'
    },
    {
        label: 'Montenegro',
        dialCode: '+382',
        value: 'ME'
    },
    {
        label: 'Montserrat',
        dialCode: '+1664',
        value: 'MS'
    },
    {
        label: 'Morocco',
        dialCode: '+212',
        value: 'MA'
    },
    {
        label: 'Mozambique',
        dialCode: '+258',
        value: 'MZ'
    },
    {
        label: 'Myanmar',
        dialCode: '+95',
        value: 'MM'
    },
    {
        label: 'Namibia',
        dialCode: '+264',
        value: 'NA'
    },
    {
        label: 'Nauru',
        dialCode: '+674',
        value: 'NR'
    },
    {
        label: 'Nepal',
        dialCode: '+977',
        value: 'NP'
    },
    {
        label: 'Netherlands',
        dialCode: '+31',
        value: 'NL'
    },
    {
        label: 'Netherlands Antilles',
        dialCode: '+599',
        value: 'AN'
    },
    {
        label: 'New Caledonia',
        dialCode: '+687',
        value: 'NC'
    },
    {
        label: 'New Zealand',
        dialCode: '+64',
        value: 'NZ'
    },
    {
        label: 'Nicaragua',
        dialCode: '+505',
        value: 'NI'
    },
    {
        label: 'Niger',
        dialCode: '+227',
        value: 'NE'
    },
    {
        label: 'Nigeria',
        dialCode: '+234',
        value: 'NG'
    },
    {
        label: 'Niue',
        dialCode: '+683',
        value: 'NU'
    },
    {
        label: 'Norfolk Island',
        dialCode: '+672',
        value: 'NF'
    },
    {
        label: 'Northern Mariana Islands',
        dialCode: '+1670',
        value: 'MP'
    },
    {
        label: 'Norway',
        dialCode: '+47',
        value: 'NO'
    },
    {
        label: 'Oman',
        dialCode: '+968',
        value: 'OM'
    },
    {
        label: 'Pakistan',
        dialCode: '+92',
        value: 'PK'
    },
    {
        label: 'Palau',
        dialCode: '+680',
        value: 'PW'
    },
    {
        label: 'Palestinian Territory, Occupied',
        dialCode: '+970',
        value: 'PS'
    },
    {
        label: 'Panama',
        dialCode: '+507',
        value: 'PA'
    },
    {
        label: 'Papua New Guinea',
        dialCode: '+675',
        value: 'PG'
    },
    {
        label: 'Paraguay',
        dialCode: '+595',
        value: 'PY'
    },
    {
        label: 'Peru',
        dialCode: '+51',
        value: 'PE'
    },
    {
        label: 'Philippines',
        dialCode: '+63',
        value: 'PH'
    },
    {
        label: 'Pitcairn',
        dialCode: '+872',
        value: 'PN'
    },
    {
        label: 'Poland',
        dialCode: '+48',
        value: 'PL'
    },
    {
        label: 'Portugal',
        dialCode: '+351',
        value: 'PT'
    },
    {
        label: 'Puerto Rico',
        dialCode: '+1939',
        value: 'PR'
    },
    {
        label: 'Qatar',
        dialCode: '+974',
        value: 'QA'
    },
    {
        label: 'Romania',
        dialCode: '+40',
        value: 'RO'
    },
    {
        label: 'Russia',
        dialCode: '+7',
        value: 'RU'
    },
    {
        label: 'Rwanda',
        dialCode: '+250',
        value: 'RW'
    },
    {
        label: 'Reunion',
        dialCode: '+262',
        value: 'RE'
    },
    {
        label: 'Saint Barthelemy',
        dialCode: '+590',
        value: 'BL'
    },
    {
        label: 'Saint Helena, Ascension and Tristan Da Cunha',
        dialCode: '+290',
        value: 'SH'
    },
    {
        label: 'Saint Kitts and Nevis',
        dialCode: '+1869',
        value: 'KN'
    },
    {
        label: 'Saint Lucia',
        dialCode: '+1758',
        value: 'LC'
    },
    {
        label: 'Saint Martin',
        dialCode: '+590',
        value: 'MF'
    },
    {
        label: 'Saint Pierre and Miquelon',
        dialCode: '+508',
        value: 'PM'
    },
    {
        label: 'Saint Vincent and the Grenadines',
        dialCode: '+1784',
        value: 'VC'
    },
    {
        label: 'Samoa',
        dialCode: '+685',
        value: 'WS'
    },
    {
        label: 'San Marino',
        dialCode: '+378',
        value: 'SM'
    },
    {
        label: 'Sao Tome and Principe',
        dialCode: '+239',
        value: 'ST'
    },
    {
        label: 'Saudi Arabia',
        dialCode: '+966',
        value: 'SA'
    },
    {
        label: 'Senegal',
        dialCode: '+221',
        value: 'SN'
    },
    {
        label: 'Serbia',
        dialCode: '+381',
        value: 'RS'
    },
    {
        label: 'Seychelles',
        dialCode: '+248',
        value: 'SC'
    },
    {
        label: 'Sierra Leone',
        dialCode: '+232',
        value: 'SL'
    },
    {
        label: 'Singapore',
        dialCode: '+65',
        value: 'SG'
    },
    {
        label: 'Slovakia',
        dialCode: '+421',
        value: 'SK'
    },
    {
        label: 'Slovenia',
        dialCode: '+386',
        value: 'SI'
    },
    {
        label: 'Solomon Islands',
        dialCode: '+677',
        value: 'SB'
    },
    {
        label: 'Somalia',
        dialCode: '+252',
        value: 'SO'
    },
    {
        label: 'South Africa',
        dialCode: '+27',
        value: 'ZA'
    },
    {
        label: 'South Sudan',
        dialCode: '+211',
        value: 'SS'
    },
    {
        label: 'South Georgia and the South Sandwich Islands',
        dialCode: '+500',
        value: 'GS'
    },
    {
        label: 'Spain',
        dialCode: '+34',
        value: 'ES'
    },
    {
        label: 'Sri Lanka',
        dialCode: '+94',
        value: 'LK'
    },
    {
        label: 'Sudan',
        dialCode: '+249',
        value: 'SD'
    },
    {
        label: 'Suriname',
        dialCode: '+597',
        value: 'SR'
    },
    {
        label: 'Svalbard and Jan Mayen',
        dialCode: '+47',
        value: 'SJ'
    },
    {
        label: 'Swaziland',
        dialCode: '+268',
        value: 'SZ'
    },
    {
        label: 'Sweden',
        dialCode: '+46',
        value: 'SE'
    },
    {
        label: 'Switzerland',
        dialCode: '+41',
        value: 'CH'
    },
    {
        label: 'Syrian Arab Republic',
        dialCode: '+963',
        value: 'SY'
    },
    {
        label: 'Taiwan',
        dialCode: '+886',
        value: 'TW'
    },
    {
        label: 'Tajikistan',
        dialCode: '+992',
        value: 'TJ'
    },
    {
        label: 'Tanzania, United Republic of Tanzania',
        dialCode: '+255',
        value: 'TZ'
    },
    {
        label: 'Thailand',
        dialCode: '+66',
        value: 'TH'
    },
    {
        label: 'Timor-Leste',
        dialCode: '+670',
        value: 'TL'
    },
    {
        label: 'Togo',
        dialCode: '+228',
        value: 'TG'
    },
    {
        label: 'Tokelau',
        dialCode: '+690',
        value: 'TK'
    },
    {
        label: 'Tonga',
        dialCode: '+676',
        value: 'TO'
    },
    {
        label: 'Trinidad and Tobago',
        dialCode: '+1868',
        value: 'TT'
    },
    {
        label: 'Tunisia',
        dialCode: '+216',
        value: 'TN'
    },
    {
        label: 'Turkey',
        dialCode: '+90',
        value: 'TR'
    },
    {
        label: 'Turkmenistan',
        dialCode: '+993',
        value: 'TM'
    },
    {
        label: 'Turks and Caicos Islands',
        dialCode: '+1649',
        value: 'TC'
    },
    {
        label: 'Tuvalu',
        dialCode: '+688',
        value: 'TV'
    },
    {
        label: 'Uganda',
        dialCode: '+256',
        value: 'UG'
    },
    {
        label: 'Ukraine',
        dialCode: '+380',
        value: 'UA'
    },
    {
        label: 'United Arab Emirates',
        dialCode: '+971',
        value: 'AE'
    },
    {
        label: 'United Kingdom',
        dialCode: '+44',
        value: 'GB'
    },
    {
        label: 'United States',
        dialCode: '+1',
        value: 'US'
    },
    {
        label: 'Uruguay',
        dialCode: '+598',
        value: 'UY'
    },
    {
        label: 'Uzbekistan',
        dialCode: '+998',
        value: 'UZ'
    },
    {
        label: 'Vanuatu',
        dialCode: '+678',
        value: 'VU'
    },
    {
        label: 'Venezuela, Bolivarian Republic of Venezuela',
        dialCode: '+58',
        value: 'VE'
    },
    {
        label: 'Vietnam',
        dialCode: '+84',
        value: 'VN'
    },
    {
        label: 'Virgin Islands, British',
        dialCode: '+1284',
        value: 'VG'
    },
    {
        label: 'Virgin Islands, U.S.',
        dialCode: '+1340',
        value: 'VI'
    },
    {
        label: 'Wallis and Futuna',
        dialCode: '+681',
        value: 'WF'
    },
    {
        label: 'Yemen',
        dialCode: '+967',
        value: 'YE'
    },
    {
        label: 'Zambia',
        dialCode: '+260',
        value: 'ZM'
    },
    {
        label: 'Zimbabwe',
        dialCode: '+263',
        value: 'ZW'
    }
]
import React, { useState, useEffect } from 'react';
import Backend from 'Backend.js';
import Alerts from 'utils/Alerts.js';
import {apiGetPositions, apiUpdateOfferPosition} from './../../services/OfferService'
import { MenuItem, Select } from '@mui/material'

const content = require('content.json');

export default function EditOfferPositionModalForm({ id, value, handleCancel, handleConfirm, confirmCB }) {

    const [positionsArr, setPositionsArr] = useState([]);
    const [position, setPosition] = useState();

    useEffect(() => {

        apiGetPositions().then(res => {
            setPositionsArr(res.message)
        })

    }, []);
    
    const handleSubmit = async () => {

        try {
            const res = await apiUpdateOfferPosition(id, position);

            handleConfirm();

            if (res.message) {
                Alerts.showSuccessAlert(res.message)
                confirmCB(position)
            }
        } catch (err) {
            Alerts.showErrorAlert(err.message);
        }
    };

    const lastPosition = value < 1000000 ? positionsArr.length : positionsArr.length + 1

    return (
        <>
            <Select
                defaultValue={value}
                size="small"
                onChange={(event) => setPosition(event.target.value)}
            >
                {value < 1000000 && (
                    <MenuItem value={1000000} key={1000000}>Remove From Best Offers</MenuItem>
                )}
                {positionsArr.map((item, idx) => (
                    <MenuItem value={item.Position} key={idx}>
                    {item.Position}
                    </MenuItem>
                ))}
                <MenuItem value={lastPosition} key={lastPosition}>Add as last</MenuItem>
            </Select>


            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.update}</button>
        </>
    );
}
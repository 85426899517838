import { Modal, Button } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MultiSelectWithSearch from './MultiSelectWithSearch'
import { COUNTRIES } from "../../constrants/countries";
import { apiUpdateOffer } from '../../services/OfferService';
import Alerts from 'utils/Alerts.js';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  height: '75%',
  bgcolor: '#ffffff',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  fontFamily: 'Poppins',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}

const ButtonCountries = ({ countries, editable, offerId, onChange }) => {
  const [openModal, setOpenModal] = useState(false)

  const selectedCountriesDefault = countries.split(" ");
  const initialValue = COUNTRIES.filter(val => selectedCountriesDefault.includes(val.value))

  const [selectedCountries, setSelectedCountries] = useState(initialValue)

  const countiesListStr = selectedCountries.map(a => a.value).join(" ");

  const onUpdate = async () => {
    const res = await apiUpdateOffer(offerId, { countries: countiesListStr })
    if(res.status == 'success'){
      Alerts.showSuccessAlert(res.message)
      onChange(countiesListStr)
      setOpenModal(false)
    }else{
      Alerts.showErrorAlert(res.message ?? "Cound't update countries")
    }
  
  }

  const onSelectChange = (result) => {
    setSelectedCountries(result);
  };

  return (
    <div>
      <div
        onClick={() => {
          setOpenModal(true)
        }}
      >
        {countries ? countries.slice(0, 8) : "Add"}
      </div>

      {openModal && (
        <Modal
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{display: 'flex', flexDirection: "column", height: "100%"}}>
              {editable && offerId && (
                <>
                  <p>Select countries:</p>
                  <MultiSelectWithSearch selected={selectedCountries} onSelectChange={onSelectChange} />
                </>
              )}
              <div style={{overflow: "auto"}}>
                <p>
                  Countires: <span>{countiesListStr}</span>
                </p>
              </div>
              {editable && offerId && (
                <Button sx={{marginTop: "auto"}} onClick={onUpdate} variant="contained">Update Countries</Button>
              )}
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  )
}

export default ButtonCountries

import React, { useState } from 'react';
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend.js';
import TextField from '@mui/material/TextField';
import { apiAddProvider } from '../../services/ProviderService.js';

const content = require('content.json');

const backendURLs = Backend.backendURLs;
const loadURL = backendURLs.offerUpdateUrl;

export default function AddNewProviderModalForm({ handleCancel, handleConfirm, confirmCB }) {
    const [providerName, setProviderName] = useState("");

    const handleSubmit = async () => {
        if (!providerName.trim()) return;

        try {
            const res = await apiAddProvider(providerName);

            handleConfirm()

            if(res.message){
                Alerts.showErrorAlert(res.message)
            }else{
                Alerts.showSuccessAlert(`New provider ${providerName} was added successfully`)
                confirmCB(providerName)
            }

        } catch (err) {

            Alerts.showErrorAlert(err.message)
        }

    };



    return (
        <>
            <TextField
                required
                id="outlined-required"
                label="Provider name"
                size="small"
                value={providerName}
                onChange={(el) => setProviderName(el.target.value)}
                className='margin-top-10'
            />

            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.add}</button>
        </>
    );
}

import React, { useState, useEffect } from 'react';
import { Alert, Stack, IconButton } from '@mui/material';
import { apiGetReviews, apiUpdateReviewStatus } from '../../services/ReviewsService';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DataFilter from '../filters/DataFilter.js';
import SearchBySelect from '../inputs/SearchBySelect.js';
import SearchField from '../inputs/SearchField.js';
import Alerts from 'utils/Alerts.js';
import DataTable from '../tables/DataTable.js';
import Tooltip from '@mui/material/Tooltip';



const initialFilter = {
    //"order": "desc",
    //"orderBy": "ProviderID",
    "page": 1,
    "pageSize": 20,
    "filterValue": "To be approved",
    "filterBy": "status"
};

const statusLookup = {
    'approved': 'Approved',
    'deleted': 'Deleted',
    'deletedByUser': 'Deleted by user',
    'rejected': 'Rejected',
    'pending': 'To be approved',
}


const alertColorLookup = {
    [statusLookup['approved']]: 'success',
    [statusLookup['deleted']]: 'error',
    [statusLookup['deletedByUser']]: 'error',
    [statusLookup['rejected']]: 'error',
    [statusLookup['pending']]: 'warning',
}



const searchBySelectData = [
    { id: 'ID', label: 'ID' },
    { id: 'email', label: 'Email' },
    { id: 'status', label: 'Review Status' }
]

const statusSelectData = [
    { id: statusLookup['approved'], label: 'Approved' },
    { id: statusLookup['rejected'], label: 'Rejected' },
    { id: statusLookup['pending'], label: 'To be Approved' },
    { id: statusLookup['deleted'], label: 'Deleted' },
    { id: statusLookup['deletedByUser'], label: 'Deleted by user' }
]

const styles = {
    approveBtn: {
        "&:hover": { color: "#2e7d32" }
    },
    rejectBtn: {
        "&:hover": { color: "#d32f2f" }
    },
    deleteBtn: {
        "&:hover": { color: "#d32f2f" }
    },
    stars: { color: "#ccc" }
}

const ReviewModeration = () => {

    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState(initialFilter);
    const [total, setTotal] = useState(null);
    const [searchBy, setSearchBy] = useState("status");


    useEffect(() => {

        fetchReviews();

    }, [filter]);

    const fetchReviews = async () => {
        setLoader(true);
        const res = await apiGetReviews(filter);
        setLoader(false);
        if (res.reviews) {
            setRows(res.reviews);
            setTotal(res?.total);
        }
    };

    const columns = [
        {
            id: 'ID',
            label: 'ID',
            sortable: false
        },
        {
            id: 'uniqueUserID',
            label: 'Unique ID',
            sortable: false
        },
        {
            id: 'email',
            label: 'Email',
            sortable: false
        },
        {
            id: 'reviewText',
            label: 'Comment',
            sortable: false,
            parse: (val, row) => <ReviewComment row={row} />
        },
        {
            id: 'status',
            label: 'Status',
            sortable: false,
            width: 200,
            parse: (val, row) => <Alert severity={alertColorLookup?.[val] || 'info'}>{val}</Alert>
        },
        {
            id: 'actionAdminName',
            label: 'Action admin name',
            sortable: false,
            width: 200
        },
        {
            label: 'Action',
            sortable: false,
            parse: (val, row) => <ActionBar row={row} />,
            width: 160
        }
    ];

    const ActionBar = ({ row }) => (
        <>
            {row.status == statusLookup['pending'] && (
                <Tooltip title="Approve" placement="top">
                    <IconButton onClick={() => updateReviewStatus(row, "approved")} sx={styles.approveBtn}>
                        <CheckCircleIcon />
                    </IconButton>
                </Tooltip>
            )}
            {row.status == statusLookup['pending'] && (
                <Tooltip title="Reject" placement="top">
                    <IconButton onClick={() => updateReviewStatus(row, "rejected")} sx={styles.rejectBtn}>
                        <DoDisturbOnIcon />
                    </IconButton>
                </Tooltip>
            )}
            {[statusLookup['pending'],statusLookup['rejected']].includes(row.status) && (
                <Tooltip title="Delete" placement="top">
                    <IconButton onClick={() => updateReviewStatus(row, "delete")} sx={styles.deleteBtn}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    )

    const ReviewComment = ({ row }) => (
        <Stack direction='column'>
            <Stack direction="row" spacing={1} ><b>{row.title}</b><Stars score={row.stars} /></Stack>
            <div>{row.reviewText}</div>
        </Stack>
    )

    const Stars = ({ score }) => {
        return (
            <span style={styles.stars}>{[...Array(score)].map(() => '★')}</span>
        )
    }


    const updateReviewStatus = async (row, newStatus) => {
        setLoader(true);
        const res = await apiUpdateReviewStatus({
            reviewID: row.ID,
            status: newStatus == "approved" ? 3 : newStatus == "rejected" ? 2 : null,
            remove: Number(newStatus == "delete")
        })
        if (res?.message) {
            Alerts.showSuccessAlert(res.message)
        }
        fetchReviews();
    };


    const onFilterChange = filter => {
        setFilter(prev => ({ ...prev, ...filter }));
    };


    return (
        <>
            <Stack direction="row" spacing={2} alignItems="center">
                <DataFilter onChange={onFilterChange}>
                    <SearchBySelect
                        name="filterBy"
                        data={searchBySelectData}
                        onChange={e => setSearchBy(e.target.value)}
                        defaultValue='status'
                    />
                    {searchBy && searchBy === "status"
                        ? (<SearchBySelect
                            label="Status"
                            name="filterValue"
                            data={statusSelectData}
                            defaultValue={statusLookup['pending']}
                        />
                        )
                        : <SearchField name="filterValue" />
                    }
                </DataFilter>
            </Stack>
            <DataTable
                columns={columns}
                tableData={rows}
                onFilterChange={onFilterChange}
                filter={filter}
                loading={loader}
                total={total}
                maxHeight={'calc(100vh - 390px)'}
            />
        </>
    );

};

export default ReviewModeration;
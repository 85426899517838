import { useState, useEffect } from 'react';
import Backend from 'Backend.js';

const backendURLs = Backend.backendURLs;
const subscriptionSettingsURL = backendURLs.subscriptionSettingsURL;

const withSubcriptionData = (SubcriptionComponent, propsCB) => {
    function Wrapper() {
        const [state, setState] = useState({
            isLoaded: false,
            isRestricted: false,
            error: null,
            subscriptionSettings: null
        });

        useEffect(() => {
            fetch(subscriptionSettingsURL, {
                method: Backend.backendMethods.get,
                headers: Backend.generateHeader()
            })
                .then(
                    async function (res) {
                        if (res.status === Backend.backendResponseCodes.internalServerErrorCode || res.status === Backend.backendResponseCodes.serviceUnavailableCode) {
                            setState({
                                isLoaded: true,
                                error: (res.statusText || !res.ok)
                            });
                        }
                        else if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                            let result = await res.json();

                            Backend.logOut();
                            setState({
                                isLoaded: true,
                                isRestricted: true
                            });
                        }
                        else {
                            let result = await res.json();

                            setState({
                                isLoaded: true,
                                subscriptionSettings: result.subscriptionSettings
                            });
                        }
                    },
                    (error) => {
                        setState({
                            isLoaded: true,
                            error
                        });
                    }
                );
        }, []);

        return <SubcriptionComponent
            data={state.subscriptionSettings}
            changeHandler={(value) => setState(prev => ({ ...prev, subscriptionSettings: value }))}
            handleConfirm={propsCB}
        />;
    }

    return Wrapper;
};

export default withSubcriptionData;
import React, { useState } from 'react';
import Images from 'utils/Images.js';

const content = require('content.json');

function SelectModalAddDetailedDescriptionForm({ 
    id, 
    stepID,
    options,
    defaultValue,
    addAnotherHandler,
    removeHandler,
    valueChangeHandler,
    isDetailed,
}) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(defaultValue || '');
    const clickHandler = () => setOpen(prev => !prev);
    const changeHandler = (newValue, id, text) => {
        setOpen(false);
        setValue(newValue);
        valueChangeHandler(id, id, stepID, text)
    }

    return (
        <>
            <div className='select-container-static description text-left margin-bottom-10' data-index={id}>
                {
                    !open && !(value || defaultValue) ?
                    <span className="font italic">{ "Click to choose..." }</span> :
                    !open && (value || defaultValue) ?
                    <span className="font bold">{ value || defaultValue }</span> :
                    null
                }
                <div className='select-option-icon' onClick={clickHandler} >
                    <img 
                        src={Images.imageURL('select-option-icon.svg')}
                        alt='Expand'
                        width='11'
                        height='11'
                        className={`${open ? 'expanded' : ''}`}
                    /> 
                </div>
                {
                    open && options.length>0 ?
                    <div className='font bold'>
                        { options.map(({ ID, Value }) => (
                            <p 
                                key={ID} 
                                className='cursor-pointer'
                                onClick={() => changeHandler(Value, ID, Value)}
                            > 
                                { Value } 
                            </p>
                        ))}
                    </div> :
                    null
                }
            </div>

            {
                isDetailed ?
                <div className='select-actions margin-top-10 margin-bottom-10'>
                    <button className='font background-transparent' onClick={addAnotherHandler} >{ `+ ${content.add_another}` }</button>
                    <button className='font background-transparent' onClick={removeHandler(stepID)}>{ content.remove }</button>
                </div> :
                null
            }
        </>
    )
}

export default SelectModalAddDetailedDescriptionForm;
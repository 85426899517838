import React from 'react';
import Header from 'components/text/Header.js';

export default function Tickets() {
    return (
        <div className='tickets-content'>
            <Header />
        </div>
    )
}

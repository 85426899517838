import Backend from 'Backend';
import Alerts from 'utils/Alerts.js';

export const apiGetUserCoinsEarning = async (filter) => {
    try {
        const queryString = new URLSearchParams(filter).toString();

        const res = await fetch(`${Backend.backendURLs.userCoinsEarningURL}?${queryString}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader()
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            return await res.json();
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }
    } catch (error) {
        Alerts.showErrorAlert('Something went wrong');
    }

};


export const apiUserValidateCoins = async (coinsEarningID) => {
    try {
        const res = await fetch(`${Backend.backendURLs.userValidateCoinsURL}`, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify({ coinsEarningID }),
        });

        if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.OKCode) {
            Alerts.showSuccessAlert(`Validated coins earning with ID: ${coinsEarningID}`)
        } else {
            Alerts.showErrorAlert('Something went wrong');
        }


    } catch (error) {
        Alerts.showErrorAlert('Something went wrong');
    }
};
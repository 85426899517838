import React, { useState, useEffect } from 'react';
import { Toolbar, Button, Paper, Box, Alert } from '@mui/material';
import Images from 'utils/Images';
import Header from 'components/text/Header.js';
import DataTable from '../components/tables/DataTable';
import { apiGetProviders } from '../services/ProviderService';
import Alerts from 'utils/Alerts.js';


const initialFilter = {
    "order": "desc",
    "orderBy": "ProviderID",
    "page": 1,
    "pageSize": 25,
    "search": "",
    "searchBy": ""
};


const Providers = () => {

    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState(initialFilter);
    const [total, setTotal] = useState(0);


    const fetchData = async () => {
        setLoader(true);
        const providers = await apiGetProviders(filter);
        setLoader(false);
        if (providers.result) {
            setRows(providers.result);
            setTotal(providers.total);
        }
    };

    useEffect(() => {

        fetchData();

    }, [filter]);

    const columns = [
        {
            id: 'ProviderID',
            label: 'ID',
        },
        {
            id: 'Prefix',
            label: 'Prefix',
        },
        {
            id: 'ProviderName',
            label: 'Name',
        }
    ];

    const onFilterChange = filter => {
        setFilter(prev => ({ ...prev, ...filter }));
    };


    return (
        <div className='upload-content'>
            <Header />
            <div className="content-container">
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                justifyContent: "space-between"
                            }}
                        >
                            <div className='flex gap-10'>
                                <img src={Images.imageURL('rectangle.svg')} alt="" />
                                <h3>Providers</h3>
                            </div>
                            <Button
                                variant="outlined"
                                onClick={() => Alerts.showAddNewProviderAlert(() => fetchData())}
                            >
                                Add Provider
                            </Button>
                        </Toolbar>
                        <DataTable
                            columns={columns}
                            tableData={rows}
                            onFilterChange={onFilterChange}
                            filter={filter}
                            loading={loader}
                            total={total}
                            fullHeight
                        />
                    </Paper>
                </Box>
            </div>
        </div>
    );

};

export default Providers;
import React, { forwardRef} from 'react';
import { Box, InputLabel, FormControl, Select, MenuItem } from '@mui/material';


const DropDown = forwardRef( (props, ref) => {

    const { 
        data, 
        name, 
        label,
        onChange,
        value,
    } = props;


    const handleChange = e => {
        if(onChange){
            onChange(e)
        }
    }

    return (
        <Box>
            <FormControl size="small" sx={{ minWidth: 150, textAlign: 'left' }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    value={value}
                    size="small"
                    inputRef={ref}
                    label={label}
                    defaultValue=""
                    name={name}
                    onChange={handleChange}
                >
                    {data.map((item, idx) => (
                        <MenuItem value={item.value} key={idx}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )

})

export default DropDown;
import Menu from 'components/widgets/Menu_Drawer.js';
import { Route, Routes } from 'react-router';
import Competitions from 'webpages/Competitions.js';
import Users from 'webpages/Users.js';
import Offers from 'webpages/Offers.js';
import Tickets from 'webpages/Tickets.js';
import Withdrawals from 'webpages/Withdrawals.js';
import OffersDone from 'webpages/OffersDone.js';
import UploadText from 'webpages/UploadText.js';
import UploadFiles from 'webpages/UploadFiles.js';
import Providers from 'webpages/Providers.js';
import User from 'webpages/User.js';
import Reviews from './webpages/Reviews';


const content = require('content.json');
const menuOptions = content.menu_options;
const configuration = require('configuration.json');
const paths = configuration.paths;


const Main = () => {
    return <div className='homepage'>
        <Menu />
        <Routes>
            <Route exact path={menuOptions.users.url} element={<Users />} />
            <Route exact path={paths.user_path} element={<User />} />
            <Route exact path={menuOptions.competitions.url} element={<Competitions />} />
            <Route exact path={menuOptions.withdrawals.url} element={<Withdrawals />} />
            <Route exact path={menuOptions.offers.url} element={<Offers />} />
            <Route exact path={menuOptions.offers_done.url} element={<OffersDone />} />
            <Route exact path={menuOptions.tickets.url} element={<Tickets />} />
            <Route exact path={menuOptions.uploadFiles.url} element={<UploadFiles />} />
            <Route exact path={menuOptions.uploadTexts.url} element={<UploadText />} />
            <Route exact path={menuOptions.providers.url} element={<Providers />} />
            <Route exact path={menuOptions.reviews.url} element={<Reviews />} />
            <Route path="*" element={<Users />} />
        </Routes>
    </div>;

};

export default Main;
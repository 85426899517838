import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import 'design/animations.css';
import 'design/buttons.css';
import 'design/checkboxes.css';
import 'design/divs.css';
import 'design/general.css';
import 'design/images.css';
import 'design/inputs.css';
import 'design/links.css';
import 'design/lists.css';
import 'design/login.css';
import 'design/selects.css';
import 'design/tables.css';
import 'design/texts.css';
import 'design/widgets.css';
import 'design/grid_list.css';
import 'design/multioffers.css';

import App from 'App.js';
import Backend from 'Backend.js';
import UserData from 'User_Data.js';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

window.backendClass = Backend;

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const queryVariables = configuration.query_variables;

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}

const msalInstance = new PublicClientApplication(msalConfig);

const token = getQueryVariable(queryVariables.token);

if (token !== null) {
    UserData.setItem(userDataKeys.jwt_token, token);
}

const element = document.getElementById('root');
const root = ReactDOM.createRoot(element);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </BrowserRouter>
    </React.StrictMode>
);

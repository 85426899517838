import React, { useState, useEffect } from 'react';
import Header from 'components/text/Header.js';
import CellRow from 'components/gridList/Cell_Row.js';
import GridList from 'components/gridList/Grid_List.js';
import Backend from 'Backend.js';
import Alerts from 'utils/Alerts.js';
import Date from 'utils/Date.js';
import { Box } from '@mui/system';
import dayjs from 'dayjs';

const content = require('content.json');
const backendURLs = Backend.backendURLs;
const listOffersDoneURL = backendURLs.listOffersDoneURL;
const getProvidersURL = backendURLs.getProvidersURL;

const statsColumns = [
    {
        headerName: 'Provider',
        field: 'Provider',
        filterable: true
    },
    {
        headerName: 'Offers Included',
        field: 'OffersIncluded',
        filterable: true
    },
    {
        headerName: 'Conversions',
        field: 'Conversions',
        filterable: true
    },
    {
        headerName: 'Chargebacks',
        field: 'Chargebacks',
        filterable: true
    },
    {
        headerName: 'Validations',
        field: 'Validations',
        filterable: true
    },
    {
        headerName: 'Manual Chargebacks',
        field: 'ManualChargebacks',
        filterable: true
    },
    {
        headerName: 'Reverse Chargebacks',
        field: 'ReverseChargebacks',
        filterable: true
    },
    {
        headerName: 'Cooking conversions, usable',
        field: 'CookingConversionsUsable',
        filterable: true
    },
    {
        headerName: 'Cooking conversions, competition',
        field: 'CookingConversionsCompetition',
        filterable: true
    },
    {
        headerName: 'Cooking conversions, all',
        field: 'CookingConversionsAll',
        filterable: true
    },
    {
        headerName: 'Total payout TCs',
        field: 'TotalPayout',
        filterable: true
    },
    {
        headerName: 'Total system payout $',
        field: 'TotalSystemPayout',
        filterable: true
    },
    {
        headerName: 'Total users payout in $',
        field: 'TotalUsersPayout',
        filterable: true
    },
    {
        headerName: 'Total payout still cooking',
        field: 'TotalPayoutStillCooking',
        filterable: true
    },
    {
        headerName: 'Total payout cooked',
        field: 'TotalPayoutCooked',
        filterable: true
    },
    {
        headerName: 'Admin actions',
        field: 'OffersDoneStatsAction',
        filterable: false
    }
];

const adminColumns = [
    {
        headerName: 'Provider',
        field: 'ProviderName',
        filterable: true
    },
    {
        headerName: 'Date Range',
        field: 'ValidationsDateRange',
        filterable: true
    },
    {
        headerName: 'Validations',
        field: 'AdminValidations',
        filterable: true
    },
    {
        headerName: 'Downgrades',
        field: 'ProviderDowngrades',
        filterable: true
    },
    {
        headerName: 'Coins Earnings Count',
        field: 'CoinsEarningsCount',
        filterable: true
    },
    {
        headerName: 'Date',
        field: 'ValidationDate',
        filterable: false
    },
    {
        headerName: 'Admin',
        field: 'AdminUsername',
        filterable: false
    },
    {
        headerName: 'Export report',
        field: 'OffersDoneAdminAction',
        filterable: false
    }
];

const initialStatsValue = [
    {
        Provider: 'Provider',
        OffersIncluded: 'OffersIncluded',
        Conversions: 'Conversions',
        Chargebacks: 'Chargebacks',
        Validations: 'Validations',
        ManualChargebacks: 'ManualChargebacks',
        ReverseChargebacks: 'ReverseChargebacks',
        CookingConversionsUsable: 'CookConvUsable',
        CookingConversionsCompetition: 'CookConvCompetition',
        CookingConversionsAll: 'CookConvAll',
        TotalPayout: 'TotalPayout',
        TotalSystemPayout: 'TotalSystemPayout',
        TotalUsersPayout: 'TotalUsersPayout',
        TotalPayoutStillCooking: 'TotalPayoutStill',
        TotalPayoutCooked: 'TotalPayoutCooked',
    }
];

const initialAdminValue = [
    {
        Provider: 'Provider',
        DateRange: 'DateRange',
        Validations: 'Validations',
        Chargebacks: 'Chargebacks',
        Reversed: 'Reversed',
        Date: 'Date',
        Admin: 'Ron',
    }
];

const yesterday = dayjs().subtract(1, 'day');
const { startDate: initialStartDate, endDate: initialEndDate } = Date.yearMMDDFormatter(yesterday, yesterday);

export default function OffersDone() {
    const [providers, setProviders] = useState();
    const initialFilter = { 
        providerID: null,
        startDate: initialStartDate,
        endDate: initialEndDate,
        amount: 10,
        page: 1,
    }

    const [page, setPage] = useState(1);
    const [switchValue, setSwitchValue] = React.useState(1);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState(initialFilter);
    const columns = !switchValue ? statsColumns : adminColumns;
    const initialValue = !switchValue ? initialStatsValue : initialAdminValue;
    const [items, setItems] = useState();
    const [itemsCount, setItemsCount] = useState(0);
    const strFilter = JSON.stringify(filter);

    const getOffersCallback = async res => {
        if (
            res.status === Backend.backendResponseCodes.internalServerErrorCode ||
            res.status === Backend.backendResponseCodes.serviceUnavailableCode
        ) {
            setError(res.statusText || !res.ok);
        } else if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.badRequestCode) {
            let result = await res.json();
            Alerts.showErrorAlert(content[result.message]);
        }
        else {
            let result = await res.json();
            setItems(result.result.validations);
            setItemsCount(result.result.validationsCount);
        }
    };

    const getProvidersCallback = async res => {
        if (
            res.status === Backend.backendResponseCodes.internalServerErrorCode ||
            res.status === Backend.backendResponseCodes.serviceUnavailableCode
        ) {
            setError(res.statusText || !res.ok);
        } else if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.badRequestCode) {
            let result = await res.json();
            Alerts.showErrorAlert(content[result.message]);
        }
        else {
            let result = await res.json();
            setProviders(result.result);
            setFilter(prev => ({ ...prev, providerID: result.result[0].ProviderID }))
        }
    };

    const getOffers = () => {
        fetch(listOffersDoneURL + `${`?${new URLSearchParams({ ...filter, page })}`}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        })
            .then(getOffersCallback)
            .catch(err => {
                setError(err);
            });
    };

    const getProviders = () => {
        fetch(getProvidersURL + `${`?${new URLSearchParams({ ...filter, page })}`}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        })
            .then(getProvidersCallback)
            .catch(err => {
                setError(err);
            });
    };

    const nextPageChangeHandler = () => {
        setPage(prev => prev + 1);
        setFilter(prev => ({
            ...prev,
            page: page + 1
        }))
    }

    const prevPageChangeHandler = () => {
        setPage(prev => prev - 1);
        setFilter(prev => ({
            ...prev,
            page: page - 1
        }))
    }

    const handleSearchChange = ({ period, filterBy, filterQuery, platform, status, country }) => {
        setFilter(prev => ({ ...prev, period, filterBy, filterQuery, platform, status, country }));
        setPage(1);
    };

    const isNextBtnInActive = ((page * 10) >= itemsCount) || (itemsCount === 0);

    useEffect(() => {
        if (page && strFilter && providers) getOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, strFilter, providers]);

    useEffect(() => {
        getProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='homepage-content'>
            <Header isSearchVisible />
            <div className="content-container">
                {
                    providers ?
                    <GridList
                        columns={columns}
                        items={items || initialValue}
                        setSwitchValue={setSwitchValue}
                        switchValue={switchValue}
                        CellRowComponent={CellRow}
                        searchBy="Offers Done"
                        error={error}
                        offersDoneFilter={filter}
                        offersDoneFilterChange={(newValue => setFilter(newValue))}
                        handleSearchChange={handleSearchChange}
                        providerFilter={providers}
                    /> :
                    null
                }
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2.5
                }}>
                    <button className='prev-page-btn' disabled={page === 1} onClick={prevPageChangeHandler}>{"<"}</button>
                    <button className='next-page-btn' disabled={isNextBtnInActive} onClick={nextPageChangeHandler}>{">"}</button>
                </Box>
            </div>
        </div>
    )
}

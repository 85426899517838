import React, { useState } from 'react';
import { Box } from '@mui/system';
import { MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const content = require('content.json');


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FinalPathInput = styled('input')({
    border: 0,
    width: '100%',
    outline: 'none',
});


export default function Uploader(props) {

    const { onSubmit, multiple, pathSelectData, loader, filenamesAfterUpload, justifyContent, accept, onChange, removeSubmitButton = false } = props;

    const [defaultFileState, setDefaultFileState] = useState(() => {
        const path = pathSelectData != null ? Object.keys(pathSelectData)[0] : "";
        return { id: "", file: "", name: "", mimeType: "", path: path }
    })

    const [files, setFiles] = useState([{ ...defaultFileState }])

    
    const deleteRow = index => {
        if (files.length == 1) {
            clearFiles();
            return;
        }

        setFiles(prev => [...prev.filter((file, idx) => idx !== index)]);
    }

    const addNewRow = () => {
        if ([...files].pop().file != "") {
            let newFiles = [...files];
            newFiles.push({ ...defaultFileState })
            setFiles(newFiles);
        }
    }

    const uploadFiles = () => {
        return onSubmit(files)
    }

    const clearFiles = () => {
        setFiles(() => ([{ ...defaultFileState }]));
    }

    const onChangeFile = async (e, index) => {
        let newFiles = [...files];
        newFiles[index].name = e.target.files[0].name
        newFiles[index].id = index
        newFiles[index].mimeType = e.target.files[0].type
        newFiles[index].raw = e.target.files[0]

        const fr = new FileReader();
        fr.readAsArrayBuffer(e.target.files?.[0]);
        fr.onload = () => {
            setFiles(() => {
                newFiles[index].file = fr.result
                return newFiles
            })
            if(typeof(onChange) == 'function'){
                return onChange(files[0]?.name ? files : []);
            }
        };

    }

    const onUpdatePath = (e, index) => {
        let newFiles = [...files];
        newFiles[index].path = e.target.value
        setFiles(newFiles);
    }

    const isDisabled = files[0].file == "";
    const UploadBtn = !removeSubmitButton && <Button sx={{ marginLeft: 'auto' }} variant="contained" disabled={isDisabled} onClick={uploadFiles} startIcon={<CloudUploadIcon />}>{content.upload}</Button>

    return (
        <Box sx={{ width: '100%' }}>

            {files.map((file, index) => (
                <Box key={index} sx={{
                    display: 'flex',
                    justifyContent: (justifyContent ?? 'flex-start'),
                    mb: 1,
                    gap: 5,
                    alignItems: 'center',
                    padding: 1,
                    background: 'white'
                }}>
                    {filenamesAfterUpload != null && filenamesAfterUpload[index]
                        ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <FinalPathInput defaultValue={filenamesAfterUpload[index]} />
                            <CheckCircleIcon style={{ color: '#00b15b' }} />
                        </Box>
                        : <> {pathSelectData && (
                            <Select
                                size="small"
                                value={file.path}
                                onChange={e => { onUpdatePath(e, index) }}
                            >
                                {Object.keys(pathSelectData).map((item, idx) => {
                                    return (
                                        <MenuItem value={item} key={idx}>
                                            {pathSelectData[item]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                <Button component="label" variant="contained" startIcon={<AttachFileOutlinedIcon />}>
                                    {content.browse}
                                    <VisuallyHiddenInput type="file" accept={accept} onChange={e => onChangeFile(e, index)} />
                                </Button>

                                {file.name && (
                                    <>
                                        {loader && (<CircularProgress />)}
                                        <small>{file.name}</small>
                                    </>
                                )}
                            </Box>
                            {multiple
                                ? <Button sx={{ marginLeft: 'auto' }} color="error" onClick={() => deleteRow(index)} startIcon={<ClearOutlinedIcon />} >{content.delete}</Button>
                                : UploadBtn
                            }
                        </>}
                </Box>
            )
            )}

            {multiple && (

                !filenamesAfterUpload
                    ? <>
                        <Box sx={{ mt: 2.5 }}>
                            <Button variant="outlined" sx={{ borderRadius: 28 }} startIcon={<AddOutlinedIcon />} onClick={addNewRow}>{content.add_file}</Button>
                        </Box>
                        <Box sx={{
                            mt: 5,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <Button sx={{ mr: 2 }} variant="outlined" onClick={clearFiles}>{content.clear_all}</Button>
                            {UploadBtn}
                        </Box>
                    </>
                    : <Button variant="contained" onClick={() => window.location.reload()} startIcon={<ReplayIcon />}>{content.upload_more_files}</Button>


            )}
        </Box>
    )
}
import React, { useState } from 'react';
import SelectActivateModalForm from './Select_Activate_Modal_Form.js';
import Backend from 'Backend.js';
import Alerts from 'utils/Alerts.js';

const content = require('content.json');
const optionPlatForms = Object.values(content.fetch_offer_platforms);
const initialValue = optionPlatForms[0];
const sendToWhomLabel = content.send_to_whom;
const usersAnswersLabel = content.users_answers;

const backendURLs = Backend.backendURLs;
const activateOfferURL = backendURLs.activateOfferUrl;
const sendHotOfferURL = backendURLs.sendHotOfferUrl;

export default function ActivateOfferModalForm({ handleCancel, handleConfirm, isSendHotOfferForm }) {
    const [location, setLocation] = useState("");
    const [error, setError] = useState("");
    const [sendToWhomValue, setSendToWhomValue] = useState(initialValue);
    const [usersAnswersValue, setUsersAnswersValue] = useState(initialValue);

    const handleSubmit = async () => {
        if (location) {
            const url = isSendHotOfferForm ? sendHotOfferURL : activateOfferURL;
            const sendData = {
                sendToWhom: sendToWhomValue,
                location,
                usersAnswers: usersAnswersValue
            }

            handleConfirm();
            sendDataHandler(url, sendData)
            .then(res => {
                if (res.ok) return res.json();
                else throw new Error(res.statusText);
            })
            .then(res => res && Alerts.showSuccessAlert(res.message))
            .catch(err => Alerts.showErrorAlert(err.message))
        }
        else !error && setError(true);
    }

    const sendDataHandler = (loadURL, data) => {
        return fetch(loadURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        }).then(async res => {
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                Backend.logOut();
            }
        });
    }

    return (
        <div>
            <div className='margin-bottom-60'>
                <SelectActivateModalForm
                    label={sendToWhomLabel}
                    value={sendToWhomValue}
                    items={optionPlatForms}
                    optionChangeHandler={(value) => setSendToWhomValue(value)}
                />

                <p className="text-left comment-ticket-popup-description margin-top-20">{ content.choose_location }</p>
                <div className='text-left'>
                    <input
                        id="give-bonus-amount"
                        type="text" 
                        maxLength={40}
                        className="give-bonus-modal-amount-input comment-ticket-popup-description margin-top-0" 
                        value={location}
                        onChange={({ target: { value } }) => setLocation(value)}
                    />
                </div>

                <SelectActivateModalForm
                    label={usersAnswersLabel}
                    value={usersAnswersValue}
                    items={optionPlatForms}
                    optionChangeHandler={(value) => setUsersAnswersValue(value)}
                />
                {
                    error ?
                    <>
                        {
                            !location ? 
                            <p className="text-left error-message margin-top-10">{content.add_location_error}</p> : 
                            null
                        }
                    </> :
                    null
                }
            </div>

            <hr className='give-bonus-swal-hr margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{ content.cancel }</button>
            <button 
                onClick={handleSubmit} 
                className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>
                { 
                    isSendHotOfferForm ?
                    content.send :
                    content.activate
                }
            </button>
        </div>
    )
}

import DropDown from 'components/inputs/DropDown.js';
import DataTable from 'components/tables/DataTable.js';
import React, { useEffect, useState } from 'react';
import DataFilter from 'components/filters/DataFilter.js';
import SearchField from '../inputs/SearchField';
import { apiGetUserReferrals } from 'services/ReferralsService.js'
import { useSearchParams } from 'react-router-dom';

const initialFilter = {
    "uniqueUserID": '',
    "page": 1,
    "generation": '',
    "order": '',
    "oderBy": '',
    "searchTerm": '',
};

const columns = [
    {
        id: 'dateCreated',
        label: 'Date Created',
    },
    {
        id: 'uniqueID',
        label: 'Unique ID',
    },
    {
        id: 'playerUsername',
        label: 'Player Username',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'platform',
        label: 'Platform',
    },
    {
        id: 'generation',
        label: 'Generation',
    },
    {
        id: 'lifetimeTCEarned',
        label: 'Lifetime TC Earned',
    },
];

const generationType = [
    { label: '-', value: '' },
    { label: 'First', value: 1 },
    { label: 'Second', value: 2 },
    { label: 'Third', value: 3 },
];

function Referrals({ generation, user }) {
    if (generation) {
        initialFilter['generation'] = generation;
    }
    let [searchParams] = useSearchParams();
    const uniqueUserID = searchParams.get('uniqueUserID') || user.uniqueID;

    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState({ ...initialFilter, uniqueUserID });
    const [total, setTotal] = useState(0);

    const fetchData = async () => {
        setLoader(true);
        const data = await apiGetUserReferrals(filter);
        setLoader(false);
        if (data) {
            setRows(data.referrals);
            setTotal(data.total);
        }
    };

    const onFilterChange = filter => {
        setFilter(prev => ({ ...prev, ...filter, page: 1 }));
    };

    const onPageChange = page => {
        setFilter(prev => ({ ...prev, page }));
    };

    const onDropDownChange = e => {
        setFilter(prev => ({ ...prev, generation: e.target.value }));
    }

    useEffect(() => {
        fetchData();
    }, [filter]);

    return (
        <div className='user-table-container'>
            <div className="user-table-handlers">
                <p className="user-table-search-name">Referrals</p>
                <div className='flex justify-between gap-5 '>
                    <DataFilter onChange={onFilterChange} submitLabel="Submit">
                        <SearchField name='searchTerm' label='Search' />
                        <DropDown
                            onChange={onDropDownChange}
                            value={filter.generation}
                            name='generation'
                            label='Generation'
                            data={generationType}
                        />
                    </DataFilter>
                </div>
            </div>
            <DataTable
                columns={columns}
                tableData={rows ? rows : []}
                onPageChange={onPageChange}
                onFilterChange={onFilterChange}
                filter={filter}
                loading={loader}
                total={total}
            />
        </div>
    );
}

export default Referrals;
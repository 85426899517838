import { useEffect, useState } from 'react';
import 'design/withdrawals.css';
import Header from 'components/text/Header';
import { Box } from '@mui/material';
import DataTable from '../components/tables/DataTable';
import { apiGetWithdrawals, apiRejectWithdrawal, apiApproveWithdrawal, apiGetWithdrawProps } from '../services/WithdrawalsService';
import { Toolbar, Paper } from '@mui/material';
import Images from 'utils/Images';
import DataFilter from '../components/filters/DataFilter';
import SearchBySelect from '../components/inputs/SearchBySelect';
import SearchField from '../components/inputs/SearchField';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Modal from '../utils/Modal';
import Alerts from 'utils/Alerts.js';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


const content = require('content.json');
const withdrawalsActions = content.withdrawals_actions;

const initialFilter = {
    order: "desc",
    orderBy: "ID",
    page: 1,
    pageSize: 20,
    search: "",
    searchBy: ""
};

const Withdrawals = () => {

    const [withdrawals, setWithdrawals] = useState([])
    const [filter, setFilter] = useState(initialFilter);
    const [totalRows, setTotalRows] = useState(0);
    const [loader, setLoader] = useState(false);


    const columns = [
        {
            label: 'ID',
            id: 'ID',
        },
        {
            label: 'Date Created',
            id: 'DateCreated',
        },
        {
            label: 'Username',
            id: 'UserName',
        },
        {
            label: 'Unique user ID',
            id: 'UniqueUserID',
        },
        {
            label: 'Email',
            id: 'Email',
        },
        {
            label: 'Platform',
            id: 'platform',
        },
        {
            label: 'Amount',
            id: 'Coins',
        },
        {
            label: 'Amount to Withdraw',
            id: 'Amount'
        },
        {
            label: 'Status',
            id: 'status',
            parse: (val, row) => {
                return (<div className={`grid-list_basic-cell_${val} badge`}>{val}</div>)
            }
        },
        {
            label: 'Date Modified',
            id: 'DateModified'
        },
        {
            label: 'Action',
            id: 'action_withdrawal',
            sortable: false,
            parse: (val, row) => {
                return row.status === "pending" ? (
                    <Stack direction="row" spacing={1}>
                        <Chip 
                            label="Approve"
                            icon={<CheckCircleIcon />}  
                            variant="outlined" 
                            color="success" 
                            onClick={async() => await openActionPopup(row, 'approve')}
                            size="small"
                        />
                        <Chip 
                            label="Reject"
                            icon={<CancelIcon />}  
                            variant="outlined" 
                            color="error" 
                            onClick={async() => await openActionPopup(row, 'reject')}
                            size="small"
                        />
                    </Stack>
                ):
                (row.status === "approved") ? (
                    <Stack direction="row" spacing={1}>
                    <Chip 
                        label="See details"
                        icon={<InfoIcon />}  
                        variant="outlined" 
                        color="info" 
                        onClick={async() => await openActionPopup(row, 'info')}
                        size="small"
                    />
                </Stack>
                ): (null)
            }
        },
    ];


    useEffect(() => {
        getWithdrawals()
    }, [filter])

    const getWithdrawals = async () => {
        setLoader(true);
        const result = await apiGetWithdrawals(filter);
        setWithdrawals([...result?.withdrawals]);
        setTotalRows(result.total);
        setLoader(false);
    };

    const rejectWithdrawal = async (id) => {
        const result = await apiRejectWithdrawal({ id });

        result?.message && Alerts.showSuccessAlert(result.message)

        getWithdrawals();
        Modal.close();
    };

    const approveWithdrawal = async (id) => {
        const result = await apiApproveWithdrawal({ id });

        result?.message && Alerts.showSuccessAlert(result.message)

        getWithdrawals();
        Modal.close();
    };

    const onFilterChange = filter => {
        setFilter(prev => ({ ...prev, ...filter }));
    }

    const openActionPopup = async (row, variant) => {
        let additionalProps;
        let pixType;
        let pixKey = "";
        if (row.additionalData) {
            additionalProps = await apiGetWithdrawProps({withdrawalID: row.ID});
            pixType = additionalProps.pixType;
            if (additionalProps.pixKey.email) {
                pixKey = additionalProps.pixKey.email;
            }
            else if (additionalProps.pixKey.phoneNumber) {
                pixKey = additionalProps.pixKey.phoneNumber;
            }
            else if (additionalProps.pixKey.randomKey) {
                pixKey = additionalProps.pixKey.randomKey;
            }
            else {
                pixKey = additionalProps.pixKey.CPFCNPJ;
            }
        }
        Modal.open(
            <Box>
                <div className="withdrawals-modal-line">
                    <p>User ID: <span>#{row.UserID}</span></p>
                    <p>Username: <span>{row.UserName}</span></p>
                </div>
                <div className="withdrawals-modal-line">
                    <p>Email Address: <span>{row.Email}</span></p>
                    <p>Platform: <span>{row.platform}</span></p>
                </div>
                <div className="withdrawals-modal-line">
                    <p>Treat Coins Amout to Withdraw: <span>{row.Coins}</span></p>
                </div>
                <div className="withdrawals-modal-line">
                    <p>Amount to Withdraw: <span>{row.Amount}</span></p>
                </div>
                {additionalProps && (
                <>
                    <div className="withdrawals-modal-line">
                        <p>Pix type: <span>{pixType}</span></p>
                    </div>
                    <div className="withdrawals-modal-line">
                        <p>Pix key: <span>{pixKey}</span></p>
                    </div>
                    <div className="withdrawals-modal-line">
                        <p>CPF / CNPJ: <span>{additionalProps.pixKey.CPFCNPJ}</span></p>
                    </div>
                </>
                )}
            </Box>,
            (variant === 'approve' || variant === 'reject') ?
            {
                onConfirm: () => {
                    return variant === 'reject' ? rejectWithdrawal(row.ID) : approveWithdrawal(row.ID)
                },
                title: <span className={`modal-popup-heading modal-heading-${variant}`}>{withdrawalsActions[variant]}</span>,
                confirmButtonText: variant === 'reject' ? 'Reject' : 'Approve',
                confirmButtonColor: variant === 'reject' ? '#d31c1c' : '#2e7d32'
            }: (null)
        )
    }


    return (
        <div className='upload-content'>
            <Header />
            <div className="content-container">
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                justifyContent: "start",
                                columnGap: 1
                            }}
                        >
                            <Box className='flex gap-10'>
                                <img src={Images.imageURL('rectangle.svg')} alt="" />
                                <h3>Withdrawals</h3>
                            </Box>

                            <DataFilter onChange={onFilterChange}>
                                <SearchBySelect data={columns} />
                                <SearchField />
                            </DataFilter>
                        </Toolbar>

                        <DataTable
                            columns={columns}
                            tableData={withdrawals}
                            onFilterChange={onFilterChange}
                            filter={filter}
                            loading={loader}
                            total={totalRows}
                            fullHeight
                        />
                    </Paper>
                </Box>
            </div>
        </div>
    )
}

export default Withdrawals;
import React, { useState } from 'react';
import Backend from 'Backend.js';
import GuideSteps from './Guide_Steps.js';
import Alerts from 'utils/Alerts.js';
import { apiGetPresignedURL, apiUploadImage } from './../../services/S3BucketService.js'
const configuration = require('configuration.json');

const content = require('content.json');

const backendURLs = Backend.backendURLs;
const submitURL = backendURLs.offersGuidesUrl;


export default function AddGuidesModalForm({ id, uniqueOfferID, values, handleCancel, handleConfirm, confirmCB }) {

    const [steps, setSteps] = useState(values || []);
    const handleSubmit = async () => {
        
        let guidesArr = [];

        for(let i=0; i<steps.length; i++){

            const step = steps[i]
            
            guidesArr.push({
                value: step.value,
                type: step.type
            })

            if (step.file) {
                
                let presignedURL = await apiGetPresignedURL(`${backendURLs.getUploadFilePresignedUrl}?path=${configuration.S3Bucket.offer_guides_path}&filename=${step.newFileName}&type=${step.raw.type}`)

                if(presignedURL?.url != null){
                    apiUploadImage(presignedURL.url, {
                        file: step.file,
                        mimeType: step.raw.type,
                        byteLength: step.file.byteLength
                    })
                }else{
                    Alerts.showErrorAlert(presignedURL.message || 'Could not upload image');
                }
            }
        }

        const data = {
            offerID: id,
            uniqueOfferID: uniqueOfferID,
            guides: guidesArr
        };

        try {
            const res = await fetch(submitURL, {
                method: Backend.backendMethods.patch,
                headers: Backend.generateHeader(),
                body: JSON.stringify(data),
            })

            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
                Backend.logOut();
                return;
            }

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            handleConfirm();
            const result = await res.json();
            if (result) {
                Alerts.showSuccessAlert(result.message)
                confirmCB(guidesArr)
            }
        } catch (err) {
            Alerts.showErrorAlert(err.message);
        }
    };


    const addNewTextStep = () => {
        const lastEl = [...steps].pop()
        if(!lastEl || lastEl.value != ""){
            setSteps((prev)=> [ ...prev, { value: "", type: 0 }])
        }
    }

    const addNewImageStep = () => {
        const lastEl = [...steps].pop()
        if(!lastEl || lastEl.value != ""){
            setSteps((prev)=> [ ...prev, { value: "", type: 1 }])
        }
    }

    const onChange = (stepIdx, step) => {

        let newSteps = [...steps];

        const newObj = { ...newSteps[stepIdx], ...step };

        newSteps[stepIdx] = newObj

        setSteps(newSteps);

    }

    const onDeleteStep = stepIdx => {

        const newValue = steps.filter((step, idx) => idx !== stepIdx);

        setSteps(newValue);
    }


    return (
        <>
            <GuideSteps
                data={steps}
                onChange={onChange}
                onDeleteStep={onDeleteStep}
            />

            <button className='font background-transparent margin-right-10' onClick={addNewTextStep} >+ Add text</button>
            <button className='font background-transparent margin-left-10' onClick={addNewImageStep} >+ Add image</button>


            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{values ? content.update : content.add}</button>
        </>
    );
}
import Backend from 'Backend';

export const apiGetPresignedURL = async (url) => {

    const res = await fetch(url, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader(),
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    let result = await res.json();

    return result

}


export const apiUploadImage = async (url, file) => {

    const res = await fetch(url, {
        method: Backend.backendMethods.put,
        body: file.file,
        headers: {
            "Content-Type": file.mimeType,
            "Content-Length": file.byteLength
        }
    })

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return res

}

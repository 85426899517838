import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'components/tables/DataTable.js';
import DataFilter from 'components/filters/DataFilter.js';
import SearchField from '../inputs/SearchField';
import ActionButton from 'components/buttons/Action_Button';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import { apiGetUserBonuses } from 'services/BonusesService.js';
import { apiUserGiveBonus } from 'services/UserProfileService.js';
import { useSearchParams } from 'react-router-dom';
import { ActionPopupContext } from 'contexts/Action_Modal_Context';
import { dropdownBonuses } from './helpers/constants.js'

const initialFilter = {
    "uniqueUserID": "",
    "amount": 20,
    "page": 1,
    "searchTerm": "",
    "orderBy": '',
    "order": '',
};

const columns = [
    {
        id: 'dateCreated',
        label: 'Date Created',
    },
    {
        id: 'amount',
        label: 'Amount',
    },
    {
        id: 'reason',
        label: 'Reason',
    },
    {
        id: 'adminName',
        label: 'Admin Username',
    },
];

const Bonuses = (user) => {
    const { handleActionPopupOpen } = useContext(ActionPopupContext);
    let [searchParams] = useSearchParams();
    const uniqueUserID = searchParams.get('uniqueUserID') || user.uniqueID;

    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState({ ...initialFilter, uniqueUserID });
    const [total, setTotal] = useState(0);


    const onPageChange = page => {
        setFilter(prev => ({ ...prev, page }));
    };

    const onFilterChange = filterObj => {
        setFilter(prev => ({
            ...prev, ...filterObj,
            page: filterObj.page ? filterObj.page : 1,
            searchTerm: filterObj.searchTerm ? filterObj.searchTerm : ''
        }));
    };

    const fetchData = async () => {
        setLoader(true);
        const data = await apiGetUserBonuses(filter);
        setLoader(false);
        if (data) {
            setRows(data.bonuses);
            setTotal(data.total);
        }
    };

    const giveBonusHandler = async (data) => {
        setLoader(true);
        await apiUserGiveBonus({ ...data, uniqueUserID });
        fetchData();
        setLoader(false);
    }

    useEffect(() => {
        fetchData();
    }, [filter]);

    return (
        <div className='user-table-container'>
            <div className="user-table-handlers">
                <p className="user-table-search-name">Bonuses</p>
                <div className='flex justify-between gap-5 '>
                    <DataFilter onChange={onFilterChange} submitLabel="Submit">
                        <SearchField name='searchTerm' label='Search' />
                    </DataFilter>
                    <div className='flex justify-between gap-5 '>
                        <ActionButton
                            onClick={() => {
                                handleActionPopupOpen({
                                    isOpened: true, actionData: {
                                        dropdown: dropdownBonuses,
                                        handleConfirm: giveBonusHandler,
                                        type: 'bonus',
                                        heading: 'Add Bonus',
                                        confirmBtnText: 'Confirm',
                                        add: true
                                    }
                                });
                            }}
                            className='user-profile-action-btn justify-between' text='Give Bonus' type='edit'>
                            <AddIcon />
                        </ActionButton>
                        <ActionButton
                            onClick={() => {
                                handleActionPopupOpen({
                                    isOpened: true, actionData: {
                                        dropdown: dropdownBonuses,
                                        handleConfirm: giveBonusHandler,
                                        type: 'bonus',
                                        heading: 'Fine User',
                                        confirmBtnText: 'Confirm',
                                        add: false
                                    }
                                });
                            }}
                            className='user-profile-action-btn justify-between' text='Fine User' type='edit'>
                            <CachedIcon />
                        </ActionButton>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                tableData={rows}
                onPageChange={onPageChange}
                onFilterChange={onFilterChange}
                filter={filter}
                loading={loader}
                total={total}
            />
        </div>
    );

};

export default Bonuses;
import React from 'react';

const content = require('content.json');

export default function AddFreeOfferModalFormSecondSection({
    info,
    infoChangeHandler
}) {
    const inputChangeHandler = (key, isBoolean) => evt => {
        const { target: { value, checked } } = evt;

        const newValue = isBoolean ? checked : value;
        infoChangeHandler(key, newValue);
    }

    const labelClickHandler = (key, value) => (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        infoChangeHandler(key, value)
    }

    return (
        <div className="free-offer-modal-second">
            <div className="flex gap-30 items-stretch margin-top-20">
                <div className="flex items-center gap-10">
                    <span className="text-left comment-ticket-popup-description inline-block">{ content.available_for_referrals }</span>

                    <div className='text-left flex items-center gap-10 flex-grow'>
                        <div className={`quiz-option-container desktop ${info.available ? 'checked' : ''}`}>
                            <div className='quiz-option-data desktop'>
                                <label onClick={labelClickHandler("available", true)} className="cursor-pointer flex">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    <span className="text comment-ticket-popup-description">{ content.yes }</span>
                                </label>
                            </div>
                        </div>

                        <div className={`quiz-option-container desktop ${!info.available ? 'checked' : ''}`}>
                            <div className='quiz-option-data desktop'>
                                <label onClick={labelClickHandler("available", false)} className="cursor-pointer flex">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    <span className="text comment-ticket-popup-description">{ content.no }</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="flex gap-30 items-stretch margin-top-20">
                <div className="flex items-center gap-10">
                    <span className="text-left comment-ticket-popup-description inline-block">{ content.user_paid }</span>

                    <div className='text-left flex items-center gap-10 flex-grow'>
                        <div className={`quiz-option-container desktop ${info.paid ? 'checked' : ''}`}>
                            <div className='quiz-option-data desktop'>
                                <label onClick={labelClickHandler("paid", true)} className="cursor-pointer flex">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    <span className="text comment-ticket-popup-description">{ content.yes }</span>
                                </label>
                            </div>
                        </div>

                        <div className={`quiz-option-container desktop ${!info.paid ? 'checked' : ''}`}>
                            <div className='quiz-option-data desktop'>
                                <label onClick={labelClickHandler("paid", false)} className="cursor-pointer flex">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    <span className="text comment-ticket-popup-description">{ content.no }</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex gap-20 margin-top-20 margin-bottom-40'>
                <div>
                    <div className='text-left flex items-center gap-5'>
                        <h2 className='text-left comment-ticket-popup-description'>{ content.show_to_active_users_in }</h2>
                        <input 
                            type='number' 
                            className="font w-100-input"
                            min={0}
                            value={info.hours}
                            onChange={inputChangeHandler("hours")}
                        />
                        <h2 className='text-left comment-ticket-popup-description'>{ content.hours }.</h2>
                    </div>
                </div>
                <div>
                    <div className='text-left flex items-center gap-5'>
                        <h2 className='text-left comment-ticket-popup-description'>{ content.showing_to }</h2>
                        <input 
                            type='number' 
                            className="font w-100-input"
                            min={0}
                            value={info.users}
                            onChange={inputChangeHandler("users")}
                        />
                        <h2 className='text-left comment-ticket-popup-description'>{ content.users }.</h2>
                    </div>
                </div>
            </div>
            
        </div>
    );
}
import Backend from 'Backend';

export const apiDownloadResourceTemplate = async (lang, modules, allowAllKeys = false, includeTranslated = false) => {

    const res = await fetch(`${Backend.backendURLs.downloadResourceTemplateUrl}?lang=${lang}&modules=${modules}&allowAllKeys=${allowAllKeys ? 1 : 0}&includeTranslated=${includeTranslated ? 1 : 0}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    
    return await res.json();

}


export const apiUploadResourceTemplate = async (fileContent, lang, ignoreValidation = false) => {

    const res = await fetch(Backend.backendURLs.uploadResourceTemplateUrl, {
        method: Backend.backendMethods.post,
        body: JSON.stringify({
            fileContent,
            lang,
            ignoreValidation
        }),
        headers: Backend.generateHeader()
    })

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();


}
import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CopyToCliboard from '../buttons/CopyToCliboard';

export default function MediaGallery({images}) {
  return (
    <ImageList style={{gridTemplateColumns: "repeat(6, 1fr)"}} sx={{ width: '100%', height: '100%' }}>
      {images.map((item, idx) => (
        <ImageListItem key={idx}>
          <img
            srcSet={`${item.path}?w=200&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.path}?w=200&fit=crop&auto=format`}
            loading="lazy"
          />
          <ImageListItemBar
            subtitle={item.path}
            actionIcon={
              <CopyToCliboard 
                copyText={item.path}
                showIcon 
                sx={{ color: "rgba(255,255,255,0.5)" }}
            />
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}


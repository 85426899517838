import React, { useState } from 'react';
import { Toolbar, Paper, Box, Tabs, Tab } from '@mui/material';
import Images from 'utils/Images';
import Header from 'components/text/Header.js';
import CustomTabPanel from 'components/tabs/CustomTabPanel';
import ReviewTopics from 'components/reviews/ReviewTopics';
import ReviewModeration from 'components/reviews/ReviewModeration';


const Reviews = () => {

    const [tab, setTab] = useState(0);

    return (
        <div className='upload-content'>
            <Header />
            <div className="content-container">
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <Toolbar
                            sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
                        >
                            <div className='flex gap-10'>
                                <img src={Images.imageURL('rectangle.svg')} alt="" />
                                <h3>Reviews</h3>
                            </div>
                        </Toolbar>
                        <Box sx={{ ml: 2, borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tab} onChange={(e, val) => setTab(val)} aria-label="basic tabs example">
                                <Tab label="Review Moderation" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                                <Tab label="Review Topics" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tab} index={0}>
                            <ReviewModeration />
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={1}>
                            <ReviewTopics />
                        </CustomTabPanel>
                    </Paper>
                </Box>
            </div>
        </div>
    );

};

export default Reviews;
import Backend from 'Backend';
import Alerts from 'utils/Alerts.js';

const configuration = require('configuration.json');
const userStatues = configuration.user_statuses;

export const apiGetUserOfferCompleteHistory = async (userId) => {

    const res = await fetch(`${Backend.backendURLs.offerCompleteHistoryUrl}?userId=${userId}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }

    return await res.json();

};


export const apiGetUsers = async (filter) => {

    const queryString = new URLSearchParams(filter).toString();

    const res = await fetch(`${Backend.backendURLs.listUsersUrl}?${queryString}`, {
        method: Backend.backendMethods.get,
        headers: Backend.generateHeader()
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        return await res.json();
    } else {
        Alerts.showErrorAlert('Something went wrong');
    }
};


export const apiDeleteUser = async (userId) => {

    const res = await fetch(Backend.backendURLs.usersActionUrl, {
        method: Backend.backendMethods.delete,
        headers: Backend.generateHeader(),
        body: JSON.stringify({ id: userId, action: configuration.user_statuses.deleted })
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        Alerts.showSuccessAlert(`User with ID${userId} deleted`);
        return await res.json();
    } else {
        let response = await res.json();
        Alerts.showErrorAlert(response.message || 'Something went wrong');
    }
};


export const apiSuspendUser = async (userId, suspendStatus) => {

    const res = await fetch(Backend.backendURLs.usersActionUrl, {
        method: Backend.backendMethods.patch,
        headers: Backend.generateHeader(),
        body: JSON.stringify({ id: userId, action: suspendStatus })
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        Alerts.showSuccessAlert(`User with ID${userId} ${suspendStatus === 2 ? 'Blocked' : 'Unblocked'}`);
        return await res.json();
    } else {
        let response = await res.json();
        Alerts.showErrorAlert(response.message || 'Something went wrong');
    }
};


export const apiClearUser = async (userId) => {

    const res = await fetch(Backend.backendURLs.usersActionUrl, {
        method: Backend.backendMethods.patch,
        headers: Backend.generateHeader(),
        body: JSON.stringify({id: userId, action: configuration.user_statuses.cleared})
    })
    
    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    }
    else if (res.status === Backend.backendResponseCodes.OKCode) {
        return await res.json();
    } else {
        let response = await res.json();
        Alerts.showErrorAlert(response.message || 'Something went wrong');
    }
}


export const apiCompleteUserOffer = async (userId, offerUniqueId, sessionId) => {

    const res = await fetch(Backend.backendURLs.offerCompleteAddUrl, {
        method: Backend.backendMethods.post,
        headers: Backend.generateHeader(),
        body: JSON.stringify({ userId, offerUniqueId, sessionId }),
    });

    if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
        Backend.logOut();
    } else if (res.status === Backend.backendResponseCodes.OKCode) {
        return await res.json();
    } else {
        Alerts.showErrorAlert('Something went wrong');
    }
};

export const deleteUserHandler = async (setLoader, userID, handleActionPopupOpen, additionalFunc) => {
    setLoader(true);
    handleActionPopupOpen({
        isOpened: true,
        actionData: {
            heading: 'Delete user',
            text: `Are you sure to delete user with ID '${userID}'?`,
            confirmBtnText: 'Delete',
            handleConfirm: async () => {
                await apiDeleteUser(userID);
                if (additionalFunc) {
                    additionalFunc();
                }
            }
        }
    });
    setLoader(false);
};

export const suspendUserHandler = async (setLoader, userID, status, handleActionPopupOpen, additionalFunc) => {
    setLoader(true);
    handleActionPopupOpen({
        isOpened: true,
        actionData: {
            heading: `${status === userStatues.suspended ? 'Block' : 'Unblock'} user`,
            text: `Are you sure to ${status === userStatues.suspended ? 'Block' : 'Unblock'} user with ID '${userID}'?`,
            confirmBtnText: status === userStatues.suspended ? 'Block' : 'Unblock',
            handleConfirm: async () => {
                await apiSuspendUser(userID, status);
                if (additionalFunc) {
                    additionalFunc();
                }
            }
        }
    });
    setLoader(false);
};
import React, { useRef } from 'react';
import Alerts from 'utils/Alerts.js';
import TextField from '@mui/material/TextField';
import { apiUpdateOffer } from '../../services/OfferService';
import { isValidUrl } from '../../utils/Validations';

const content = require('content.json');


export default function EditClickthroughModalForm({offerId, value, handleCancel, handleConfirm, confirmCB} ) {
    
    const clickthroughUrlRef = useRef(null);

    const handleSubmit = async () => {

        const newUrlValue = clickthroughUrlRef.current.value;

        if (!newUrlValue.trim()) return;

        if(!isValidUrl(newUrlValue) || newUrlValue.indexOf('<user-id>') == -1){
            return Alerts.showErrorAlert('Please provide a valid URL')
        }


        try {
            const res = await apiUpdateOffer(offerId, {templateURL: newUrlValue});

            handleConfirm()

            if(res.status == 'success'){
                Alerts.showSuccessAlert(`ClickThrough Url updated`)
                confirmCB(newUrlValue)
            }else{
                Alerts.showErrorAlert(res.message)
            }

        } catch (err) {

            Alerts.showErrorAlert(err.message)
        }

    };



    return (
        <>
            <TextField
                required
                id="outlined-required"
                label="Provider name"
                size="small"
                inputRef={clickthroughUrlRef}
                defaultValue={value}
                className='margin-top-10'
                fullWidth
            />

            <hr className='give-bonus-swal-hr margin-top-20 margin-bottom-10' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.edit}</button>
        </>
    );
}

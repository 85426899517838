import React, { useCallback, useEffect, useState } from 'react';
import Backend from 'Backend.js';
import Alerts from 'utils/Alerts.js';
import AddHotOfferModalFormFirstSection from 'components/forms/Add_Hot_Offer_Modal_Form_First_Section.js';
import AddHotOfferModalFormThirdSection from 'components/forms/Add_Hot_Offer_Modal_Form_Third_Section.js';
import AddHotOfferModalFormFourthSection from 'components/forms/Add_Hot_Offer_Modal_Form_Fourth_Section.js';

const configuration = require('configuration.json');
const businessLogic = configuration.business_logic;
const secondsInHour = businessLogic.seconds_in_hour;

const content = require('content.json');
const allDevicesLabelText = content.device_types;

const backendURLs = Backend.backendURLs;
const listCountriesUrl = backendURLs.listCountriesUrl;
const loadURL = backendURLs.addHotOfferUrl;

export default function AddHotOfferModalForm({
    offerID,
    isPercentageOffer,
    isHot,
    item
}) {
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState(isHot ? item['HotIn'] : null);
    const [info, setInfo] = useState({
        minBalance: isHot ? item['MinBalanceToShow'] : 0.00,
        maxBalance: isHot ? item['MaxBalanceToShow'] : 0.00,
        device: isHot ? item['HotPlatformType'] : allDevicesLabelText.All,
        expireTime: isHot ? item['ExpireTime'] : 0,
        newCoins: isHot ? item['NewCoins'] : null,
        newPercentage: isHot ? item['NewPercentage'] : null,
        blockForNonLegitimateUsers:  isHot ? !item['ShowToNotLegitimateUsers'] : false,
        showOnlyToPurchasedUsers:  isHot ? item['ShowOnlyToPurchasedUsers'] : false,
    });
    const [state, setState] = useState({
        '1': null,
        '2': [],
        '3': [],
        '4': [],
        '5': [],
        '6': null,
        '7': null,
        '8': null,
        '9': [],
        '10': null,
        '11': [],
        '12': null,
        '13': null,
        '14': null,
        '15': null,
        '16': null,
        '17': null,
        '18': null,
        '19': null,
        '20': null,
        ...JSON.parse(item?.IntroSurveyResponseFilter ? item?.IntroSurveyResponseFilter : "{}"),
    });
    const [countries, setCountries] = useState([])

    const isValidated = () => {
        return (
            country && 
            info.maxBalance > 0 &&
            +info.maxBalance >= +info.minBalance &&
            info.expireTime > 0 &&
            (info.newCoins || info.newPercentage) > 0
        );
    }

    const numberWithDecimals = useCallback((value) => Number((+value).toFixed(2)), []);

    const handleSubmit = () => {
        if (!isValidated()) return;

        let introSurveyResponseFilter = {};
        let newCoins = info.newCoins ? numberWithDecimals(info.newCoins) : info.newCoins;
        let newPercentage = info.newPercentage ? numberWithDecimals(info.newPercentage) : info.newPercentage;
        let minBalanceToShow = numberWithDecimals(info.minBalance);
        let maxBalanceToShow = numberWithDecimals(info.maxBalance);

        for (let key in state) {
            const value = state[key];


            if (Array.isArray(value)) {
                if (value.length > 0) 
                    introSurveyResponseFilter[key] = value.map((selection) => parseInt(selection));
            }
            else {
                if (value) introSurveyResponseFilter[key] = parseInt(value);
            }
        }

        if (Object.keys(introSurveyResponseFilter).length === 0) introSurveyResponseFilter = null;

        const sendData = { 
            offerID,
            country,
            platformName: info.device,
            newCoins,
            newPercentage,
            validity: info.expireTime * secondsInHour,
            minBalanceToShow,
            maxBalanceToShow,
            showToLegitimateUsers: 1,
            showToNotLegitimateUsers: Number(!info.blockForNonLegitimateUsers),
            showOnlyToPurchasedUsers: Number(info.showOnlyToPurchasedUsers),
            introSurveyResponseFilter,
        };

        setLoading(true);
        sendDataHandler(sendData)
            .then(async res => {
                setLoading(false);

                const result = await res.json();
                if (res.ok) Alerts.showSuccessAlert(result.message);
                else Alerts.showErrorAlert(result.message);
            });
    };

    const sendDataHandler = (data) => {
        return fetch(loadURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        })
    };

    const getCountriesCallback = async res => {
        if (
            res.status === Backend.backendResponseCodes.internalServerErrorCode ||
            res.status === Backend.backendResponseCodes.serviceUnavailableCode
        ) {
            return null;
        } else if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } else if (res.status === Backend.backendResponseCodes.badRequestCode) {
            let result = await res.json();
            Alerts.showErrorAlert(content[result.message]);
        }
        else {
            let result = await res.json();

            const countries = result.countries.reduce((res, cur) => {
                const code = cur.code;
                const name = cur.name;
                res[code] = name;
                return res;
            }, {});
            setCountries(countries);
        }
    };

    const getCountries = () => {
        fetch(listCountriesUrl, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        })
            .then(getCountriesCallback)
            .catch(err => null);
    }

    const changeHandler = (key, value) => {
        setState(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const infoChangeHandler = (key, value) => {
        setInfo(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const multipleChangeHandler = (key, value) => {
        let newValue;
        if (state[key].includes(value)) {
            newValue = state[key].filter(each => each !== value);
        }
        else {
            newValue = Array.from(new Set([...state[key], value]));
        }

        setState(prev => ({
            ...prev,
            [key]: newValue
        }))
    }

    useEffect(() => {
        getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='text-right overflow-hidden add-hot-offer-container'>
            <AddHotOfferModalFormFirstSection 
                isPercentageOffer={isPercentageOffer}
                data={state}
                changeHandler={changeHandler}
                info={info}
                infoChangeHandler={infoChangeHandler}
                multipleChangeHandler={multipleChangeHandler}
                disabled={isHot}
            />
            <AddHotOfferModalFormThirdSection
                data={state}
                disabled={isHot}
                changeHandler={changeHandler}
            />
            <AddHotOfferModalFormFourthSection
                data={state}
                selectedCountry={country}
                countryChangeHandler={newValue => setCountry(newValue)}
                countries={countries}
                changeHandler={changeHandler}
                disabled={isHot}
                multipleChangeHandler={multipleChangeHandler}
            />
            <button 
                disabled={loading || isHot}
                onClick={handleSubmit} 
                className='swal2-confirm swal2-styled give-bonus-modal-confirm-button capitalize'
            >
                { content.save_offer }
            </button>
        </div>
    );
}

import React from 'react';
import MultipleSelectModalForm from 'components/forms/Multiple_Select_Modal_Form.js';
import AddHotOfferModalSelect from 'components/forms/Add_Hot_Offer_Modal_Select.js';

import { format } from 'date-fns'

const content = require('content.json');
const deviceTypes = content.device_types;
const questions = content.hot_offer_questions;
const chooseLabelText = content.choose_label;
const allDevicesLabelText = content.device_types;

export default function AddHotOfferModalFormFirstSection({
    isPercentageOffer,
    data,
    changeHandler,
    info,
    disabled,
    infoChangeHandler,
    multipleChangeHandler
}) {
    const inputChangeHandler = (key, isBoolean) => evt => {
        const { target: { value, checked } } = evt;

        const newValue = isBoolean ? checked : value;
        infoChangeHandler(key, newValue);
    }

    const inputDataChangeHandler = key => newValue => infoChangeHandler(key, newValue);

    const dataChangeHandler = key => newValue => changeHandler(key, newValue);

    const multipleDataChangeHandler = key => newValue => multipleChangeHandler(key, newValue);

    return (
        <div
            style={disabled ? { pointerEvents: "none", opacity: "0.7" } : {}}
            className="hot-offer-modal-first">
            <div className="text-right margin-bottom-20">
                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <div className='text-left'>
                            <h2 className='text-left comment-ticket-popup-description margin-bottom-10'>{ content.min_balance_usd }</h2>
                            <input 
                                type='number' 
                                className="font w-full"
                                min={0.00}
                                step={"0.01"}
                                value={info.minBalance}
                                onChange={inputChangeHandler("minBalance")}
                            />
                        </div>
                    </div>
                    <div className="flex-50">
                        <div className='text-left'>
                            <h2 className='text-left comment-ticket-popup-description margin-bottom-10'>{ content.max_balance_usd }</h2>
                            <input 
                                type='number' 
                                className="font w-full"
                                min={0.00}
                                step={"0.01"}
                                value={info.maxBalance}
                                onChange={inputChangeHandler("maxBalance")}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <div className='text-left'>
                            <AddHotOfferModalSelect
                                defaultDropdownText={info.device || allDevicesLabelText.all}
                                value={info.device}
                                optionChangeHandler={inputDataChangeHandler("device")}
                                label={content.device}
                                items={Object.entries(deviceTypes)}
                            />
                        </div>
                    </div>
                    <div className="flex-50">
                        <div className='text-left'>
                            <h2 className='text-left comment-ticket-popup-description margin-bottom-10'>{disabled ? content.expire_time_at : content.expire_time_hr}</h2>
                            {disabled ?
                                <>
                                    <p  className='comment-ticket-popup-description margin-top-20'>{format(new Date(info.expireTime), 'hh:mm:ss dd/MM/yyyy')}</p>
                                </>
                                :   
                            <input 
                                    type='number'
                                    className="font w-full"
                                    min={0.00}
                                    value={info.expireTime}
                                    onChange={inputChangeHandler("expireTime")}
                            />
                            }
                        </div>
                    </div>
                    <div className="flex-50">
                        <div className='text-left'>
                            <h2 className='text-left comment-ticket-popup-description margin-bottom-10'>{ isPercentageOffer ? content.new_percentage : content.new_coins }</h2>
                            <input 
                                type='number' 
                                className="font w-full"
                                min={0.00}
                                step={"0.01"}
                                value={Number(isPercentageOffer ? info.newPercentage : info.newCoins)}
                                onChange={inputChangeHandler(isPercentageOffer ? "newPercentage" : "newCoins")}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <label className="checkbox-container subscription-settings-container">
                        <span className="notification-setting-label">{ content.add_hot_offer_modal_checkbox1_text }</span>
                        <input 
                            type="checkbox" 
                            name="receiveNotificationsTelegram" 
                            className="subscription-settings-checkbox" 
                            checked={info.blockForNonLegitimateUsers}
                            onChange={inputChangeHandler("blockForNonLegitimateUsers", true)}
                        />
                        <span className="checkmark-checkbox left-checkbox"></span>
                    </label>
                    <label className="checkbox-container subscription-settings-container">
                        <span className="notification-setting-label">{ content.add_hot_offer_modal_checkbox2_text }</span>
                        <input 
                            type="checkbox" 
                            name="receiveNotificationsTelegram" 
                            className="subscription-settings-checkbox" 
                            checked={info.showOnlyToPurchasedUsers}
                            onChange={inputChangeHandler("showOnlyToPurchasedUsers", true)}
                        />
                        <span className="checkmark-checkbox left-checkbox"></span>
                    </label>
                </div>

                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[1].answers[data['1']]}
                            optionChangeHandler={dataChangeHandler("1")}
                            label={questions[1].question}
                            items={Object.entries(questions[1].answers)}
                        />
                    </div>
                    <div className="flex-50">
                        <MultipleSelectModalForm
                            defaultDropdownText={chooseLabelText}
                            value={data['2']}
                            optionChangeHandler={multipleDataChangeHandler("2")}
                            label={questions[2].question}
                            items={Object.entries(questions[2].answers)}
                        />
                    </div>
                </div>

                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <MultipleSelectModalForm
                            defaultDropdownText={chooseLabelText}
                            value={data['3']}
                            optionChangeHandler={multipleDataChangeHandler("3")}
                            label={questions[3].question}
                            items={Object.entries(questions[3].answers)}
                        />
                    </div>
                    <div className="flex-50">
                        <MultipleSelectModalForm
                            defaultDropdownText={chooseLabelText}
                            value={data['4']}
                            optionChangeHandler={multipleDataChangeHandler("4")}
                            label={questions[4].question}
                            items={Object.entries(questions[4].answers)}
                        />
                    </div>
                </div>

                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <MultipleSelectModalForm
                            defaultDropdownText={chooseLabelText}
                            value={data['5']}
                            optionChangeHandler={multipleDataChangeHandler("5")}
                            label={questions[5].question}
                            items={Object.entries(questions[5].answers)}
                        />
                    </div>
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[6].answers[data['6']]}
                            optionChangeHandler={dataChangeHandler("6")}
                            label={questions[6].question}
                            items={Object.entries(questions[6].answers)}
                        />
                    </div>
                </div>

                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[7].answers[data['7']]}
                            optionChangeHandler={dataChangeHandler("7")}
                            label={questions[7].question}
                            items={Object.entries(questions[7].answers)}
                        />
                    </div>
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[8].answers[data['8']]}
                            optionChangeHandler={dataChangeHandler("8")}
                            label={questions[8].question}
                            items={Object.entries(questions[8].answers)}
                        />
                    </div>
                </div>

                <div className="flex gap-30 items-stretch margin-top-20">
                    <div className="flex-50">
                        <MultipleSelectModalForm
                            defaultDropdownText={chooseLabelText}
                            value={data['9']}
                            optionChangeHandler={multipleDataChangeHandler("9")}
                            label={questions[9].question}
                            items={Object.entries(questions[9].answers)}
                        />
                    </div>
                    <div className="flex-50">
                        <AddHotOfferModalSelect
                            defaultDropdownText={chooseLabelText}
                            value={questions[10].answers[data['10']]}
                            optionChangeHandler={dataChangeHandler("10")}
                            label={questions[10].question}
                            items={Object.entries(questions[10].answers)}
                        />
                    </div>
                </div>

                <div className="margin-top-20 half-width">
                    <div className="flex-50">
                        <MultipleSelectModalForm
                            defaultDropdownText={chooseLabelText}
                            value={data['11']}
                            optionChangeHandler={multipleDataChangeHandler("11")}
                            label={questions[11].question}
                            items={Object.entries(questions[11].answers)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
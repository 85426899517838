import React, { useContext, useState } from 'react';
import { Box, Tab, styled } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ActivityLog from 'components/user/ActivityLog';
import Bonuses from 'components/user/Bonuses';
import PaymentHistory from 'components/user/PaymentHistory';
import ProfileInfo from 'components/user/ProfileInfo';
import Referrals from 'components/user/Referrals';
import TreatCoinsFeed from 'components/user/TreatCoinsFeed';
import Images from 'utils/Images.js';
import { UserContext } from 'contexts/UserContext';

const mapForFeed = {
    usable: 1,
    competition: 2,
    cookingCoins: 3,
    used: 4,
    totalCoins: 5,
};

const mapForReferrals = {
    totalReferrals: '',
    firstGenReferrals: 1,
    secondGenReferrals: 2,
    thirdGenReferrals: 3,
}

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: 0
}));

const UserDetails = (props) => {

    const { user } = useContext(UserContext);

    const [currentTab, setCurrentTab] = useState('1');
    const [tabType, setTabType] = useState(null);


    const onTabChange = (event, newValue, key, filteringType) => {
        if (filteringType === 'tc_feed') {
            setTabType(mapForFeed[key]);
        } else if (filteringType === 'referrals') {
            setTabType(mapForReferrals[key])
        }
        setCurrentTab(newValue);
    };

    return (
        <Box sx={{
            width: "100%",
            padding: 3
        }}>

            <Box sx={{
                paddingBottom: 5
            }}>
                <div className="user-page-content">
                    <div className='flex w-full justify-between'>
                        <div className='flex gap-10'>
                            <img className='user-page-head-img' src={Images.imageURL('rectangle.svg')} alt="" />
                            <div className="user-page-head-creds">
                                <h3 className='font-14'>{user?.username || 'Username'}</h3>
                                <p className='header-desc text-left font-12'>{user?.email || 'Email'} </p>
                            </div>
                            <div className="user-page-place-container">
                                <img className='user-page-place-image' src={Images.imageURL('user/pentangle1.png')} alt="" />
                                <p className='user-page-place number'>#</p>
                                <p className='user-page-place value'>{user?.place|| 1}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
            <Box>
                <UserContext.Provider 
                    value={{
                        changeTab: (newValue) => setCurrentTab(newValue)
                    }} 
                >
                    <TabContext value={currentTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                onChange={onTabChange}
                                variant="scrollable"
                                scrollButtons={true}
                            >
                                <Tab label="Profile Info" value="1" />
                                <Tab label="Treat Coins Feed" value="2" />
                                <Tab label="Referrals" value="3" />
                                <Tab label="Activity Log" value="4" />
                                <Tab label="Payment History" value="5" />
                                <Tab label="Bonuses" value="6" />
                            </TabList>
                        </Box>
                        <StyledTabPanel value="1">
                            <ProfileInfo onTabChange={onTabChange} user={user} />
                        </StyledTabPanel>
                        <StyledTabPanel value="2">
                            <TreatCoinsFeed status={tabType} userID={user?.ID} user={user} />
                        </StyledTabPanel>
                        <StyledTabPanel value="3">
                            <Referrals generation={tabType} userId={user?.ID} user={user} />
                        </StyledTabPanel>
                        <StyledTabPanel value="4">
                            <ActivityLog userId={user?.ID} user={user} />
                        </StyledTabPanel>
                        <StyledTabPanel value="5">
                            <PaymentHistory userId={user?.ID} user={user} />
                        </StyledTabPanel>
                        <StyledTabPanel value="6">
                            <Bonuses userId={user?.ID} user={user} />
                        </StyledTabPanel>
                    </TabContext>
                </UserContext.Provider>
            </Box>
        </Box>
    )

}

export default UserDetails;
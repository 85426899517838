

class Formatting {

    static format(text, formats, values) {
        for (let i = 0, len = Math.min(formats.length, values.length); i < len; i++) {
            text = text.replace(formats[i], (values[i] !== null) ? values[i] : '');
        }
        return text;
    }

    static formatUSDAmount(number, showDecimals = false) {
        if (showDecimals) return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number.toLocaleString();
    }

    static formatTreatCoinAmount(number) {
        return (number !== undefined && number !== null) ? number.toLocaleString() : null;
    }

    static createOrdinal(number) {
        let j = number % 10,
            k = number % 100;
        if (j === 1 && k !== 11) {
            return number + "st";
        }
        if (j === 2 && k !== 12) {
            return number + "nd";
        }
        if (j === 3 && k !== 13) {
            return number + "rd";
        }
        return number + "th";
    }

    static shortenText(str, maxLength) {
        let wideLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        let strToCompare = str;
        if (!strToCompare) return '';
        let wideLetter;
        for (let i = 0, len = wideLetters.length; i < len; i++) {
            wideLetter = wideLetters[i];
            strToCompare = strToCompare.replaceAll(wideLetter, wideLetter + wideLetter);
        }
        if (strToCompare.length > maxLength) {
            let stringToReturn = strToCompare.substring(0, maxLength) + '...';
            for (let i = 0, len = wideLetters.length; i < len; i++) {
                wideLetter = wideLetters[i];
                stringToReturn = stringToReturn.replaceAll(wideLetter + wideLetter, wideLetter);
            }
            return stringToReturn;
        }
        else {
            return str;
        }
    }
}

export default Formatting;
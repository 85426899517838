import React, { useState } from 'react';
import Images from 'utils/Images.js';

function SelectActivateModalForm({ label, value, items=[], optionChangeHandler }) {
    const [open, setOpen] = useState(false);
    const clickHandler = () => setOpen(prev => !prev);
    const changeHandler = (value) => {
        optionChangeHandler(value);
        setOpen(false);
    }

    return (
        <div className='select-container'>
            <h2 className='text-left comment-ticket-popup-description'>{ label }</h2>
            <div className='select-container-static text-left'>
                <span className="font">{ value }</span>
                <div 
                    className='select-option-icon' 
                    onClick={clickHandler} >
                    <img 
                        src={Images.imageURL('select-option-icon.svg')}
                        alt='Expand'
                        width='11'
                        height='11'
                        className={`${open ? 'expanded' : ''}`}
                    /> 
                </div>
                {
                    open && items.length>0 ?
                    <div className='font'>
                        { items.filter(each => each!==value).map(each => (
                            <p 
                                className='cursor-pointer'
                                key={each} 
                                onClick={() => changeHandler(each)}
                            > 
                                { each } 
                            </p>
                        ))}
                    </div> :
                    null
                }
            </div>
        </div>
    )
}

export default SelectActivateModalForm;
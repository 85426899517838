import React, { useState } from 'react';

const content = require('content.json');

export default function GiveBonusModalForm({ handleCancel, handleConfirm }) {
    const [amount, setAmount] = useState("");
    const [reason, setReason] = useState("");
    const [error, setError] = useState(false);

    const handleSubmit = () => {
        if (amount && reason) {
            handleConfirm();
        }
        else !error && setError(true);
    }

    return (
        <div>
            <div>
                <p className="text-left">{ content.amount }</p>
                <input
                    id="give-bonus-amount"
                    type="number" 
                    placeholder="0" 
                    className="give-bonus-modal-amount-input" 
                    value={amount}
                    onChange={({ target: { value } }) => setAmount(value)}
                />
            </div>
            <div>
                <p className="text-left">{ content.reason }</p>
                <textarea 
                    value={reason}
                    onChange={({ target: { value } }) => setReason(value)}
                    className='give-bonus-modal-reason-input'></textarea>
            </div>

            {
                error ?
                <>
                    {
                        !amount ? 
                        <p className="text-left error-message margin-0">{content.add_amount_error}</p> : 
                        null
                    }
                    {
                        !reason ? 
                        <p className="text-left error-message margin-0">{content.add_reason_error}</p> : 
                        null
                    }
                </> :
                null
            }
            <hr className='give-bonus-swal-hr' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{ content.cancel }</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{ content.give }</button>
        </div>
    )
}

import React from 'react';

const content = require('content.json');

export default function CloseTicketModalForm({ handleCancel, handleConfirm }) {
    const handleSubmit = () => {
        handleConfirm();
    }

    return (
        <div>
            <div>
                <p className="close-modal-description text-left">{ content.close_ticket_description }</p>
            </div>

            <hr className='give-bonus-swal-hr' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{ content.cancel }</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{ content.close }</button>
        </div>
    )
}

import React, { useState } from 'react';

const content = require('content.json');

export default function CommentTicketModalForm({ handleCancel, handleConfirm }) {
    const [comment, setComment] = useState("");
    const [error, setError] = useState(false);
    const [file, setFile] = useState(null);

    const handleSubmit = () => {
        if (comment) {
            handleConfirm();
        }
        else !error && setError(true);
    }

    return (
        <div className='comment-ticket-modal-container'>
            <div>
                <div className="text-left comment-ticket-popup-description">{ content.comment }</div>
                <textarea 
                    value={comment}
                    onChange={({ target: { value } }) => setComment(value)}
                    className='give-bonus-modal-reason-input'></textarea>
            </div>
            <div className="text-left comment-ticket-popup-description">{ content.attachments }</div>
            <div className="text-left margin-bottom-10">
                <label htmlFor="attachment" className="comment-ticket-popup-description text-underline margin-right-10">{ content.upload }</label>
                <input id="attachment" type="file" onChange={({ target: { files }}) => setFile(files?.[0])} />
                { file && <span className="comment-ticket-popup-description">{ file.name }</span> }
            </div>

            {
                error ?
                <>
                    {
                        !comment ? 
                        <p className="text-left error-message margin-0">{content.add_comment_error}</p> : 
                        null
                    }
                </> :
                null
            }
            <hr className='give-bonus-swal-hr' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{ content.cancel }</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{ content.comment }</button>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import Header from 'components/text/Header.js';
import Uploader from 'components/widgets/Uploader';
import { Box } from '@mui/system';
import { apiGetPresignedURL, apiUploadImage } from '../services/S3BucketService'
import Alerts from 'utils/Alerts.js';
import Backend from 'Backend';
import { Tabs, Tab } from '@mui/material';
import CustomTabPanel from '../components/tabs/CustomTabPanel';
import MediaGallery from '../components/galeries/MediaGallery';
import { apiUpdateMediaGallery, apiGetMediaGallery } from '../services/MediaGalleryService';

const globalConf = require('global_conf.json');

const content = require('content.json');


const fileLocationDropDown = {
    "landing/static/media/": "/landing/static/media",
    "landing/static/css/": "/landing/static/css"
}

export default function UploadFiles() {

    const [isUploading, setIsUploading] = useState(false)
    const [filePathOnServer, setFilePathOnServer] = useState(false)
    const [tab, setTab] = useState(0);
    const [images, setImages] = useState([]);

    const onSubmit = async (files) => {

        setIsUploading(true)

        let errMsg = "";
        let filesPath = {};

        for (let i = 0; i < files.length; i++) {
            try {
                var file = files[i];
                const PresignedURL = await apiGetPresignedURL(`${Backend.backendURLs.getUploadFilePresignedUrl}?path=${file.path}&filename=${file.name}&type=${file.mimeType}`)
                if (PresignedURL.success && PresignedURL?.url != null) {
                    const uploadRes = await apiUploadImage(PresignedURL.url, file)
                    if (!uploadRes.ok) {
                        throw { message: uploadRes.statusText };
                    }
                } else {
                    throw { message: PresignedURL.message }
                }

                const host = globalConf.website_connection.user_host;

                filesPath[file.id] = `${host}/${file.path}${file.name}`
                setFilePathOnServer(prev => ({ ...prev, ...filesPath }))

                apiUpdateMediaGallery(filesPath[file.id])

            } catch (error) {
                errMsg = `${errMsg} ${file.name} - ${(error?.message)} </br>`;
            }
        }

        errMsg ? Alerts.showErrorAlert(errMsg) : Alerts.showSuccessAlert(`${(files.length > 1 ? content.files : content.file)} ${content.uploaded_successfully}`);

        setIsUploading(false)

    }

    const fetchMediaGallery = async () => {
        const mediaGallery = await apiGetMediaGallery()
        setImages(mediaGallery.media)
    }

    useEffect(() => {

        if (tab == 1) {
            fetchMediaGallery();
        }

    }, [tab])


    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div className='upload-content'>
            <Header />
            <div className="content-container">
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%', bgcolor: 'white' }}>
                        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" centered>
                            <Tab label="Upload Media" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                            <Tab label="Media Gallery" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tab} index={0}>
                        <Box sx={{ mt: 2.5 }}>
                            <Uploader
                                loader={isUploading}
                                multiple
                                pathSelectData={fileLocationDropDown}
                                onSubmit={onSubmit}
                                filenamesAfterUpload={filePathOnServer}
                            />
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={tab} index={1}>
                        <MediaGallery
                            images={images}
                        />
                    </CustomTabPanel>
                </Box>

            </div>
        </div>
    )
}
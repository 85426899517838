import Backend from 'Backend.js';
import React from 'react';
import Alerts from 'utils/Alerts.js';

const content = require('content.json');

const backendURLs = Backend.backendURLs;
const loadURL = backendURLs.deactivateOfferUrl;

export default function DeactivateOfferModalForm({ handleCancel, handleConfirm }) {

    const handleSubmit = async () => {
        const sendData = {};

        handleConfirm();
        sendDataHandler(sendData)
            .then(res => {
                if (res.ok) return res.json();
                else throw new Error(res.statusText);
            })
            .then(res => res && Alerts.showSuccessAlert(res.message))
            .catch(err => Alerts.showErrorAlert(err.message));
    };

    const sendDataHandler = (data) => {
        return fetch(loadURL, {
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(data),
        }).then(async res => {
            if (res.status === Backend.backendResponseCodes.unauthorizedCode) {

                Backend.logOut();
            }
        });
    };

    return (
        <div>
            <div>
                <p className="close-modal-description text-left">{content.deactivate_offer_description}</p>
            </div>

            <hr className='give-bonus-swal-hr' />
            <button onClick={handleCancel} className='swal2-cancel swal2-styled give-bonus-modal-cancel-button margin-right-10'>{content.cancel}</button>
            <button onClick={handleSubmit} className='swal2-confirm swal2-styled give-bonus-modal-confirm-button'>{content.deactivate}</button>
        </div>
    );
}
